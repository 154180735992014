import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../constants';

const CategoryCard = ({ categories, letter }) => (
  <div className="single-category-card">
    <div className="single--letter">
      <span>{ letter }</span>
    </div>
    <div className="single--list-wrapper">
      { categories && categories.length > 0 && categories.map(category => (
          <Link to={ `${ ROUTES.CATEGORY_DETAIL.replace('/:id', '') }/${ category.id }` } key={ category.id }>
            { category.title }
          </Link>
        ))
      }
    </div>
  </div>
);

export default CategoryCard;