import { ACTIVITY_ACTIONS, INITIAL_STATE } from '../constants';

export default function activityReducer(state = INITIAL_STATE.ACTIVITY_STATE, action) {
  const { values, error, meta } = action.payload || {};

  switch(action.type) {
    case ACTIVITY_ACTIONS.GET_ACTIVITY_SUCCESS: {
      return {
        ...state,
        values,
        meta
      };
    }
    case ACTIVITY_ACTIONS.GET_ACTIVITY_ERROR: {
      return {
        ...state,
        error,
      }
    }
    case ACTIVITY_ACTIONS.RESET: {
      return INITIAL_STATE.ACTIVITY_STATE
    }
    default:
      return state;
  }
};
