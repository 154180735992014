import React from 'react';
import { sortSubcategories, removeHtmlFromSubcategories, removeHtmlFromText } from '../../../services/Helpers';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../constants';
import EditIcon from '../../../imgs/edit-icon.svg';
import DeletedIcon from '../../../imgs/deleted-icon.svg';

const SmallResult = ({ categories, category, user, onEditCategory, onDeleteCategory }) => (
  <div className="results-wrapper-small">
    { categories && categories.length > 0 && categories.map(category =>
      <div className="card" key={ category.id }>
        <p className="category--name">{ category.title }</p>
        <div className="category--items">
          { category.subcategories.length > 0 && sortSubcategories(removeHtmlFromSubcategories(category.subcategories)).map(subcategory =>
            <Link
              key={ subcategory.id }
              to={ user && user.role !== 'admin' ?
                `${ ROUTES.CONSULTATION_DETAILS.replace('/:id', '') }/${ subcategory.id }` :
                `${ ROUTES.CONSULT_EDIT.replace('/:id', '') }/${ subcategory.id }`
              }
              className='cat-item'
            >
              { removeHtmlFromText(subcategory.subcategory) }
            </Link>
          )}
      </div>
    </div>
    )}
    { category &&
      <div className="card">
        <p className="category--name">{ category.title }
          { user && user.role === 'admin' &&
              <div style={{ float: 'right' }}>
                <button className='btn btn-remove-cat' onClick={ onDeleteCategory }>
                  <img src={ DeletedIcon } alt="icon" />
                </button>
                <button className='btn btn-remove-cat' onClick={ onEditCategory }>
                  <img src={ EditIcon } alt="icon" />
                </button>
              </div>
          }
        </p>
        <div className="category--items">
          { category.subcategories && category.subcategories.length > 0 && sortSubcategories(removeHtmlFromSubcategories(category.subcategories)).map(subcategory =>
            <Link
              key={ subcategory.id }
              to={ user && user.role !== 'admin' ?
                `${ ROUTES.CONSULTATION_DETAILS.replace('/:id', '') }/${ subcategory.id }` :
                `${ ROUTES.CONSULT_EDIT.replace('/:id', '') }/${ subcategory.id }`
              }
              className='cat-item'
            >
              { removeHtmlFromText(subcategory.subcategory) }
            </Link>
          )}
      </div>
    </div>
    }
  </div>
);

export default SmallResult;