import React, {PureComponent} from 'react';
import StripeCheckout from 'react-stripe-checkout';
import Subscription from '../..//Subscription';
import {STRIPE} from '../../../../constants';

export class PaymentsForm extends PureComponent {
  startFree = token => {
    const {startFreeTrial} = this.props;
    const id = token.id;
    startFreeTrial(id);
  }

  handleCreatePayment = token => {
    const {createPayment} = this.props;
    const id = token.id;
    createPayment(id);
  }

  renderPaymentButton = COST => {
    const {user} = this.props;
    const {subscriptions} = this.props;
    const hasFree = subscriptions.filter(subscription => subscription.subscription_type === 'free')[0];
    return user ? (
      <StripeCheckout
        email={user.email}
        panelLabel="Submit payment"
        stripeKey={STRIPE.STRIPE_KEY}
        amount={COST[hasFree ? 1 : 0].cost}
        allowRememberMe={false}
        currency={STRIPE.CURRENCY}
        token={hasFree ? this.handleCreatePayment : this.startFree}
      >
        <button className="btn large filled">
          {hasFree ? `R ${COST[1].cost / 100} - Purchase` : 'Free - Purchase'}
        </button>
      </StripeCheckout>
    ) : null;
  }

  renderTableHeader = () => {
    return (
      <div className="list-item" style={{
        display: 'flex',
        flex: '1',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        border: 0,
        boxShadow: 'none',
        marginBottom: 0,
      }}>
        <div className="item-profile--user" style={{width: '25%'}}>
          <p>Type</p>
        </div>
        <div className="item-profile--user" style={{width: '25%'}}>
          <p>Payment Date</p>
        </div>
        <div className="item-profile--user" style={{width: '25%'}}>
          <p>Access Ends</p>
        </div>
        <div className="item-profile--user" style={{width: '25%'}}>
          <p>Cost</p>
        </div>
        <div className="item-profile--user" style={{width: '25%'}}>
          <p>Status</p>
        </div>
      </div>
    );
  }

  renderSubscriptionsList = () => {
    const {subscriptions, user} = this.props;
    return subscriptions.map(subscription => (
      <Subscription key={subscription.created_at} subscription={subscription} user={user} />
    ));
  }

  render() {
    const { user, subscriptions } = this.props;
    return (
      <div className="activity-list">
        {subscriptions.length > 0 && user ? (
            <div className="list-container">
              {this.renderTableHeader()}
              {this.renderSubscriptionsList()}
            </div>
          ) : null
        }
        <div className="list-footer" style={{display: 'flex', justifyContent: 'center'}}>
          {this.renderPaymentButton(STRIPE.COST)}
        </div>
      </div>
    )
  };
}

PaymentsForm.fields = [];
