import React from 'react';

const LetterCategoriesBox = ({ letter, categories, onCategoryClick, selectedCategory }) => (
  <div className={
    `letter-box ${
      selectedCategory && selectedCategory.title.charAt(0).toUpperCase() === letter ? 'select' : '' }`}>
    <div className="letter-container ">
      <p>{ letter }</p>
    </div>
    <div className="cat-wrapper">
      { categories.map(category =>
        <div
          key={ category.id }
          className={`single-category ${ selectedCategory && selectedCategory.id === category.id ? 'active' : '' }`}
          onClick={ () => onCategoryClick(category) }
        >
          <span>{ category.title }</span>
          <span>{ category.subcategories_count }</span>
        </div>
      )}
    </div>
  </div>
);

export default LetterCategoriesBox;