import keymirror from 'keymirror';

export default keymirror({
  LOGIN: null,
  LOGIN_SUCCESS: null,
  LOGIN_ERROR: null,
  SIGN_UP: null,
  SIGN_UP_SUCCESS: null,
  SIGN_UP_ERROR: null,
  LOGOUT: null,
  LOGOUT_SUCCESS: null,
  LOGOUT_ERROR: null,
  ENTER_USERNAME_AND_PASSWORD_ERROR: null,
  FORGOT_PASSWORD: null,
  FORGOT_PASSWORD_SUCCESS: null,
  FORGOT_PASSWORD_ERROR: null,
  SAVE_NEW_PASSWORD: null,
  SAVE_NEW_PASSWORD_SUCCESS: null,
  SAVE_NEW_PASSWORD_ERROR: null,
  CHANGE_PASSWORD: null,
  CHANGE_PASSWORD_SUCCESS: null,
  CHANGE_PASSWORD_ERROR: null,
  GET_USER_DATA: null,
  UPDATE_USER: null,
  UPDATE_USER_SUCCESS: null,
  UPDATE_USER_ERROR: null,
  REQUEST_USER_DATA: null,
  REQUEST_USER_DATA_SUCCESS: null,
  REQUEST_USER_DATA_ERROR: null
});