import React, { Component } from 'react';
import _ from 'lodash';
import { AuthHeader, FormContainer, NewPasswordForm } from '../../';
import { PASSWORD } from '../../../constants';

class NewPassword extends Component {
  constructor() {
    super();

    this.state = {
      password: '',
      password_confirmation: '',
      errors: {}
    };
  }

  handlePassword = event => {
    const password = event.target.value;

    this.setState({ password });
  }

  handlePasswordConfirmation = event => {
    const password_confirmation = event.target.value;

    this.setState({ password_confirmation });
  }

  onSubmit = event => {
    event.preventDefault();

    const { password, password_confirmation } = this.state;
    const { authActions: { saveNewPassword }, location: { search } } = this.props;
    const token = search.replace('?token=', '');
    let errors = {};

    if(!password && password === '') {
      errors.password = 'Please enter your password.';
    } else if(!PASSWORD.test(password)) {
      errors.password = 'Password must be more than 7 characters & contain at least one number & one capital letter.';
    }
    if(password && !password_confirmation) {
      errors.password_confirmation = 'Please confirm password.';
    } else if(password && password_confirmation && password !== password_confirmation) {
      errors.password = ' ';
      errors.password_confirmation = 'Password do not match.';
    }
    if(_.isEmpty(errors)) {
      saveNewPassword(password, password_confirmation, token);
    } else {
      this.setState({ errors });
    }
  }

  render() {
    const { authState: { errorMessage } } = this.props;

    return (
      <main>
        <AuthHeader
          title='New Password'
          description="Must be more than 7 characters & contain at least one number & one capital letter."
        />
        <FormContainer classStyle='newpass'>
          { errorMessage &&
            <span className='main-auth-error'>{ errorMessage }</span>
          }
          <NewPasswordForm
            onSubmit={ this.onSubmit }
            handlePassword={ this.handlePassword }
            handlePasswordConfirmation={ this.handlePasswordConfirmation }
            errorMessage={ this.props.authState.errorMessage }
            { ...this.state }
          />
        </FormContainer>
      </main>
    );
  }
}

export default NewPassword;
