import { CONSULTATIONS_ACTIONS, INITIAL_STATE } from '../constants';

export default function consultationsReducer(state = INITIAL_STATE.CONSULTATIONS_STATE, action) {
  const { consultation, error, successMessage, meta, consultations } = action.payload || {};

  switch(action.type) {
    case CONSULTATIONS_ACTIONS.GET_CONSULTATION_SUCCESS: {
      return {
        ...state,
        consultation,
        error: null
      };
    }
    case CONSULTATIONS_ACTIONS.GET_CONSULTATION_ERROR:
    case CONSULTATIONS_ACTIONS.SAVE_CONSULTATION_ERROR:
    case CONSULTATIONS_ACTIONS.EDIT_CONSULTATION_ERROR:
    case CONSULTATIONS_ACTIONS.DELETE_CONSULTATION_ERROR: {
      return {
        ...state,
        error
      };
    }
    case CONSULTATIONS_ACTIONS.CLEAR_CONSULTATION: {
      return {
        ...state,
        consultation: null,
        error: null
      };
    }
    case CONSULTATIONS_ACTIONS.EDIT_CONSULTATION_SUCCESS:
    case CONSULTATIONS_ACTIONS.SAVE_CONSULTATION_SUCCESS: {
      return {
        ...state,
        successMessage
      };
    }
    case CONSULTATIONS_ACTIONS.CLEAR_MESSAGE: {
      return {
        ...state,
        successMessage: '',
        error: null
      };
    }
    case CONSULTATIONS_ACTIONS.GET_UPDATED_CONSULTATIONS:
      return {
        ...state,
        meta,
      };
    case CONSULTATIONS_ACTIONS.GET_UPDATED_CONSULTATIONS_SUCCESS:
      return {
        ...state,
        consultations,
      };
    case CONSULTATIONS_ACTIONS.GET_UPDATED_CONSULTATIONS_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        consultations: [...state.consultations, ...consultations],
      };
    default:
      return state;
  }
};