import { takeEvery, put, call } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { AUTH_ACTIONS, ROUTES } from '../constants';
import { authActions, globalActions } from '../actions';
import { processRequest } from '../services/Api';

function* handleLogin(action) {
  try {
    const { email, password } = action.payload;
    const requestPayload = {
      user: {
        email,
        password
      }
    };
    const { data } = yield call(processRequest, '/authorizations/login', 'POST', requestPayload);

    if(data.user) {
      yield put(authActions.loginSuccess(data.user));
      yield put(replace(ROUTES.HOME));
    } else if(data.error_message && data.error_message === 'Wrong email or password') {
      yield put(authActions.loginError('The email or password you have entered is incorrect. Please try again.'));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status, statusText } = response || {};
    const { error_messages } = data || {};

    if(status === 400) {
      const keys = Object.keys(error_messages);
      const errorMessage = error_messages[keys[0]];
      const message = error_messages && `${ keys[0] } ${ errorMessage }`;

      yield put(authActions.loginError(message));
    } else if(status === 500) {
      yield put(authActions.loginError(statusText));
    } else {
      yield put(authActions.loginError('Internal server error.'));
    }
  }
}

function* handleSignUp(action) {
  try {
    const { user } = action.payload;
    const {
      first_name,
      last_name,
      email,
      email_confirmation,
      password,
      password_confirmation,
      // country,
      // gp_type,
      gmc,
      // practice_name,
      user_type,
      email_notifications,
      nmc_pin,
      // practice_postcode
    } = user;
    const formData = new FormData();

    formData.append('user[first_name]', first_name);
    formData.append('user[last_name]', last_name);
    formData.append('user[email]', email);
    formData.append('user[email_confirmation]', email_confirmation);
    formData.append('user[password]', password);
    formData.append('user[password_confirmation]', password_confirmation);
    // formData.append('user[country]', country);
    formData.append('user[user_type]', user_type);
    formData.append('user[email_notifications]', email_notifications);

    // if(gp_type)
    //   formData.append('user[gp_type]', gp_type);
    // if(practice_name)
    //   formData.append('user[practice_name]', practice_name);
    // if(practice_postcode)
    //   formData.append('user[practice_postcode]', practice_postcode);
    if(gmc)
      formData.append('user[imc]', gmc);
    if(nmc_pin)
      formData.append('user[nmc_pin]', nmc_pin);
    const { data } = yield call(processRequest, '/users', 'POST', formData, false);

    if(data.user && data.user.id) {
      yield put(authActions.signUpSuccess(data.user));
      yield put(replace(ROUTES.HOME));
    } else if(data.error) {
      yield put(authActions.signUpError(data.error));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status, statusText } = response || {};
    const { error_messages } = data || {};

    if(status === 400) {
      const keys = Object.keys(error_messages);
      const errorMessage = error_messages[keys[0]];
      const message = error_messages && `${ keys[0] } ${ errorMessage }`;

      yield put(authActions.signUpError(message));
    } else if(status === 500) {
      yield put(authActions.signUpError(statusText));
    } else {
      yield put(authActions.signUpError('Internal server error.'));
    }
  }
}

function* handleUpdateUser(action) {
  try {
    const { user } = action.payload;
    const {
      first_name,
      last_name,
      email,
      avatar,
      // county,
      // gp_type,
      imc,
      // practice_name,
      user_type,
      email_notifications,
      id,
      nmbi
    } = user;
    const formData = new FormData();

    formData.append('user[first_name]', first_name);
    formData.append('user[last_name]', last_name);
    formData.append('user[email]', email);
    // formData.append('user[county]', county);
    formData.append('user[user_type]', user_type);
    formData.append('user[email_notifications]', email_notifications);

    if(avatar)
      formData.append('user[avatar]', avatar);
    // if(gp_type)
    //   formData.append('user[gp_type]', gp_type);
    // if(practice_name)
    //   formData.append('user[practice_name]', practice_name);
    if(imc)
      formData.append('user[imc]', imc);
    if(nmbi)
      formData.append('user[nmbi]', nmbi);

    const { data } = yield call(processRequest, `/users/${ id }`, 'PUT', formData, false);

    if(data.user && data.user.id) {
      yield put(authActions.updateUserSuccess(data.user));
      yield put(replace(ROUTES.HOME));
    } else if(data.error) {
      yield put(authActions.updateUserError(data.error));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status, statusText } = response || {};
    const { error_messages } = data || {};

    if(status === 400) {
      const keys = Object.keys(error_messages);
      const errorMessage = error_messages[keys[0]];
      const message = error_messages && `${ keys[0] } ${ errorMessage }`;

      yield put(authActions.updateUserError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess(statusText));
      yield put(replace(ROUTES.HOME));
    } else if(status === 500) {
      yield put(authActions.updateUserError(statusText));
    } else {
      yield put(authActions.updateUserError('Internal server error.'));
    }
  }
}

function* handleForgotPassword(action) {
  try {
    const { email } = action.payload;
    const requestPayload = { email };

    yield call(processRequest, '/users/forgot_password', 'PUT', requestPayload);
    yield put(replace(ROUTES.FORGOT_PASSWORD_CONFIRMATION));

    yield put(authActions.forgotPasswordSuccess('We sent you an email.'));
  } catch(e) {
    const { response} = e || {};
    const { data, status, statusText } = response || {};
    const { error_message } = data || {};

    if(status === 400) {
      yield put(authActions.forgotPasswordError(error_message));
    } else if(status === 500) {
      yield put(authActions.forgotPasswordError(statusText));
    } else {
      yield put(authActions.forgotPasswordError('Internal server error.'));
    }
  }
}

function* handleSaveNewPassword(action) {
  try {
    const { password, password_confirmation, token } = action.payload;
    const requestPayload = { password, password_confirmation };

    yield call(processRequest, `/users/update_password?token=${ token }`, 'PUT', requestPayload);

    yield put(authActions.saveNewPasswordSuccess('New password saved successfully.'));
    yield put(replace(ROUTES.SIGN_IN));
  } catch(e) {
    const { response} = e || {};
    const { data, status, statusText } = response || {};
    const { error_messages } = data || {};

    if(status === 400) {
      const keys = Object.keys(error_messages);
      const errorMessage = error_messages[keys[0]];
      const message = error_messages && `${ keys[0] } ${ errorMessage }`;

      yield put(authActions.saveNewPasswordError(message));
    } else if(status === 500) {
      yield put(authActions.saveNewPasswordError(statusText));
    } else {
      yield put(authActions.saveNewPasswordError('Internal server error.'));
    }
  }
}

function* handleChangePassword(action) {
  try {
    const { passwordData } = action.payload;
    const { old_password, password, password_confirmation } = passwordData;

    const requestPayload = { old_password, password, password_confirmation };
    const { data } = yield call(processRequest, '/users/change_password', 'PUT', requestPayload);

    yield put(authActions.changePasswordSuccess(data.user, 'Password successfully changed.'));
  } catch(e) {
    const { response} = e || {};
    const { data, status, statusText } = response || {};
    const { error_message } = data || {};

    if(status === 400) {
      yield put(authActions.changePasswordError(error_message === 'Invalid old password' ? 'Incorrect password specified. Please try again' : error_message));
    } else if(status === 500) {
      yield put(authActions.changePasswordError(statusText));
    } else {
      yield put(authActions.changePasswordError('Internal server error.'));
    }
  }
}

function* handleLogout() {
  try {
    yield call(processRequest, '/authorizations/logout', 'PUT', {}, true);
    yield put(globalActions.toggleMenu(false));
    yield put(authActions.logoutSuccess());
    yield put(replace(ROUTES.HOME));
  } catch(e) {
    const { response} = e || {};
    const { data, status, statusText } = response || {};
    const { error_messages } = data || {};

    if(status === 400) {
      const keys = Object.keys(error_messages);
      const errorMessage = error_messages[keys[0]];
      const message = error_messages && `${ keys[0] } ${ errorMessage }`;

      yield put(authActions.logoutError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess(statusText));
      yield put(replace(ROUTES.HOME));
    } else if(status === 500) {
      yield put(authActions.logoutError(statusText));
    } else {
      yield put(authActions.logoutError('Internal server error.'));
    }
  }
}

function* handleGetUserData() {
  try {
    const { data } = yield call(processRequest, '/users/my', 'GET');

    if(data.user) {
      yield put(authActions.requestUserDataSuccess(data.user));
    } else if(data.error_message && data.error_message === 'Wrong email or password') {
      yield put(authActions.requestUserDataError('The email or password you have entered is incorrect. Please try again.'));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status, statusText } = response || {};
    const { error_messages } = data || {};

    if(status === 400) {
      const keys = Object.keys(error_messages);
      const errorMessage = error_messages[keys[0]];
      const message = error_messages && `${ keys[0] } ${ errorMessage }`;

      yield put(authActions.requestUserDataError(message));
    } else if(status === 401) {
      yield put(authActions.requestUserDataError(statusText));
      yield put(replace(ROUTES.HOME));
    } else if(status === 500) {
      yield put(authActions.requestUserDataError(statusText));
    } else {
      yield put(authActions.requestUserDataError('Internal server error.'));
    }
  }
}

export function* watchAuthSagas() {
  yield takeEvery(AUTH_ACTIONS.LOGIN, handleLogin);
  yield takeEvery(AUTH_ACTIONS.SIGN_UP, handleSignUp);
  yield takeEvery(AUTH_ACTIONS.UPDATE_USER, handleUpdateUser);
  yield takeEvery(AUTH_ACTIONS.FORGOT_PASSWORD, handleForgotPassword);
  yield takeEvery(AUTH_ACTIONS.SAVE_NEW_PASSWORD, handleSaveNewPassword);
  yield takeEvery(AUTH_ACTIONS.CHANGE_PASSWORD, handleChangePassword);
  yield takeEvery(AUTH_ACTIONS.LOGOUT, handleLogout);
  yield takeEvery(AUTH_ACTIONS.REQUEST_USER_DATA, handleGetUserData);
};