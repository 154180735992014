import _ from 'lodash';
import moment from 'moment';

export const sortCategoriesAlphabetically = categories => {
  const sorted = categories.reduce((a, c) => {
    let k = c.title.charAt(0).toUpperCase();

    if (a[k]) a[k].push(c)
    else a[k] = [c]

    return a;
  }, {});

  return sorted;
}

export const getLetters = sortedCategories => {
  return _.orderBy(_.keys(sortedCategories), String, ['asc']);
}

export const sortByLetter = items => {
  const sorted = items.reduce((a, c) => {
    let k = c.letter.charAt(0).toUpperCase();

    if (a[k]) a[k].push(c)
    else a[k] = [c]

    return a;
  }, {});

  return sorted;
}

export const getCommentCreatedDate = date => {
  return `${ (moment().diff(date, 'days') >= 1) ?
    moment(date).format('MMMM DD') :
    moment(date).calendar().split(' ')[0] } at ${ moment(date).format('HH:mm') }`;
}

export const sortReports = reports => {
  const sorted = reports.reduce((a, c) => {
    let k = new Date(c.created_at);
    let month = k.getMonth() + 1;

    if (a[month]) a[month].push(c)
    else a[month] = [c]

    return a;
  }, {});

  return sorted;
}

export const getSortedReportsMonthKeys = reports => {
  return _.orderBy(_.keys(reports), String, ['desc']);
}

export const formatedDateForReports = month => {
  return moment().set('month', Number(month - 1)).format('MMM YY');
}

export const removeHtmlFromText = text => {
  return text.replace(/<\/?[^>]+(>|$)/g, '');
}

export const removeHtmlFromSubcategories = subcategories => {
  return subcategories.map(subcategory => {
    return {
      ...subcategory,
      subcategory: subcategory.subcategory.replace(/<\/?[^>]+(>|$)/g, '')
    }
  })
}

export const sortSubcategories = subcategories => {
  return subcategories.sort((subcategory_a, subcategory_b) => subcategory_a.subcategory.localeCompare(subcategory_b.subcategory));
}

export const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const differenceInDays = date => {
  return moment(date).diff(moment(), 'days')
};