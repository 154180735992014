import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES, IMAGE_URL } from '../../../constants';
import { sortSubcategories, removeHtmlFromSubcategories, removeHtmlFromText } from '../../../services/Helpers';
import LetterCategoriesBox from './LetterCategoriesBox';
import EditIcon from '../../../imgs/edit-icon.svg';
import DeletedIcon from '../../../imgs/deleted-icon.svg';
import PDFIcon from '../../../imgs/pdf-icon.svg';

const LargeResult = ({
  letters,
  categories,
  onCategoryClick,
  selectedCategory,
  subcategories,
  user,
  onDeleteCategory,
  onEditCategory,
  onDeleteConsultation
}) => (
  <div className="results-wrapper-large">
    <div className="results-container">
      <div className="panel-categories">
        { letters.map(letter =>
          <LetterCategoriesBox
            key={ letter }
            letter={ letter }
            categories={ categories[letter] }
            onCategoryClick={ onCategoryClick }
            selectedCategory={ selectedCategory }
          />
        )}
      </div>
      { selectedCategory &&
        <div className="panel-catresults">
          <p className="cat-title">{ selectedCategory.title }
            { user && user.role === 'admin' &&
              <div style={{ float: 'right' }}>
                <button className='btn btn-remove-cat' onClick={ onDeleteCategory }>
                  <img src={ DeletedIcon } alt="icon" />
                </button>
                <button className='btn btn-remove-cat' onClick={ onEditCategory }>
                  <img src={ EditIcon } alt="icon" />
                </button>
              </div>
            }
          </p>
          { subcategories ?
            (sortSubcategories(removeHtmlFromSubcategories(subcategories)).map(subcategory =>
              <div
                key={ subcategory.id }
                className='admin-consultation-action-container'
              >
                <Link
                  to={ user && user.role !== 'admin' ?
                    `${ ROUTES.CONSULTATION_DETAILS.replace('/:id', '') }/${ subcategory.id }` :
                    `${ ROUTES.CONSULT_EDIT.replace('/:id', '') }/${ subcategory.id }`
                  }
                  className='cat-result'
                >
                  { removeHtmlFromText(subcategory.subcategory) }
                </Link>
                {subcategory.pdf_url ?
                  <a className='btn btn-remove-cat' style={{flex: 1, justifyContent: 'flex-start', marginTop: '0', marginLeft: '7px'}} href={`${IMAGE_URL}/${subcategory.pdf_url}`} target='_blank'>
                    <img src={ PDFIcon } alt="icon" />
                  </a> : null
                }
                { user && user.role === 'admin' &&
                  <button className='btn btn-remove-cat' onClick={ () => onDeleteConsultation(subcategory.id, selectedCategory.id) }>
                    <img src={ DeletedIcon } alt="icon" />
                  </button>
                }
              </div>
            )) :
            (selectedCategory.subcategories && sortSubcategories(removeHtmlFromSubcategories(selectedCategory.subcategories)).map(subcategory =>
              <div
                key={ subcategory.id }
                className='admin-consultation-action-container'
              >
                <Link
                  key={ subcategory.id }
                  to={ user && user.role !== 'admin' ?
                    `${ ROUTES.CONSULTATION_DETAILS.replace('/:id', '') }/${ subcategory.id }` :
                    `${ ROUTES.CONSULT_EDIT.replace('/:id', '') }/${ subcategory.id }`
                  }
                  className='cat-result'
                >
                  { removeHtmlFromText(subcategory.subcategory) }
                </Link>
                {subcategory.pdf_url ?
                  <a className='btn btn-remove-cat' style={{flex: 1, justifyContent: 'flex-start', marginTop: '0', marginLeft: '7px'}} href={`${IMAGE_URL}/${subcategory.pdf_url}`} target='_blank'>
                    <img src={ PDFIcon } alt="icon" />
                  </a> : null
                }
                { user && user.role === 'admin' &&
                  <button className='btn btn-remove-cat' onClick={ () => onDeleteConsultation(subcategory.id, selectedCategory.id) }>
                    <img src={ DeletedIcon } alt="icon" />
                  </button>
                }
              </div>
            ))
          }
        </div>
      }
    </div>
  </div>
);

export default LargeResult;