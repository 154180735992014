import React, { Component } from 'react';
import { AuthHeader } from '../../';

class ContactUs extends Component {
  render() {
    return (
      <AuthHeader
        title='CONTACT US'
        description='GPConsult.ie is an online service offering a range of consultation templates for conditions commonly seen in General Practice in Ireland. These templates have been created by a number of Irish GPs working at the coal face of General Practice and can be used to help document the symptoms, signs and management plans of these conditions.  GPConsult.ie is only open to GPs and Practice Nurses. It was founded by Dr Paul Ryan, a GP based in Cork and launched in June 2019. If you need to contact us for any reason, please email us at support@prescriptionrevision.ie. We would love to hear from you.'
      />
    );
  }
}

export default ContactUs;