import { takeEvery, put, call } from 'redux-saga/effects';
import { COMMENTS_ACTIONS } from '../constants';
import { commentsActions, consultationsActions, authActions, reportsActions } from '../actions';
import { processRequest } from '../services/Api';

function* handleAddComment(action) {
  try {
    const { commentData } = action.payload;
    const formData = new FormData();

    formData.append('comment[consultation_id]', commentData.consultation_id);
    formData.append('comment[body]', commentData.body);

    commentData.attachments.forEach((attachment, index) => {
      formData.append(`comment[attachments_attributes[${ index }]document]`, attachment);
    });

    const { data } = yield call(processRequest, `/comments`, 'POST', formData, false);

    if(data.comment) {
      yield put(commentsActions.addCommentSuccess('Your comment has been successfully posted.'));
      yield put(consultationsActions.getConsultation(commentData.consultation_id));
    } else if(data.error_message) {
      yield put(commentsActions.addCommentError(data.error_message));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status } = response || {};
    const { error_messages } = data || {};
    const keys = Object.keys(error_messages);
    const errorMessage = error_messages[keys[0]];
    const message = error_messages && `${ keys[0] } ${ errorMessage }`;

    if(status === 400) {
      yield put(commentsActions.addCommentError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess());
    } else {
      yield put(commentsActions.addCommentError('Internal server error.'));
    }
  }
}

function* handleAddReply(action) {
  try {
    const { replyData } = action.payload;
    const formData = new FormData();

    formData.append('reply[consultation_id]', replyData.consultation_id);
    formData.append('reply[comment_id]', replyData.comment_id);
    formData.append('reply[body]', replyData.body);

    replyData.attachments.forEach((attachment, index) => {
      formData.append(`reply[attachments_attributes[${ index }]document]`, attachment);
    });

    const { data } = yield call(processRequest, `/replies`, 'POST', formData, false);

    if(data.reply) {
      yield put(commentsActions.addReplySuccess('Your reply has been successfully posted.'));
      yield put(consultationsActions.getConsultation(replyData.consultation_id));
      yield put(commentsActions.getComment(replyData.comment_id));
    } else if(data.error_message) {
      yield put(commentsActions.addReplyError(data.error_message));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status } = response || {};
    const { error_messages } = data || {};
    const keys = Object.keys(error_messages);
    const errorMessage = error_messages[keys[0]];
    const message = error_messages && `${ keys[0] } ${ errorMessage }`;

    if(status === 400) {
      yield put(commentsActions.addReplyError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess());
    } else {
      yield put(commentsActions.addReplyError('Internal server error.'));
    }
  }
}

function* handleLikeForComment(action) {
  try {
    const { comment_id, consultation_id } = action.payload;
    const requestPayload = {
      id: comment_id
    };

    const { data } = yield call(processRequest, `/likes/like_for_comment`, 'POST', requestPayload);

    if(data.count || data.count === 0) {
      yield put(commentsActions.likeForCommentSuccess(data.count));
      yield put(consultationsActions.getConsultation(consultation_id));
    } else if(data.error_message) {
      yield put(commentsActions.likeForCommentError(data.error_message));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status } = response || {};
    const { error_messages } = data || {};
    const keys = Object.keys(error_messages);
    const errorMessage = error_messages[keys[0]];
    const message = error_messages && `${ keys[0] } ${ errorMessage }`;

    if(status === 400) {
      yield put(commentsActions.likeForCommentError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess());
    } else {
      yield put(commentsActions.likeForCommentError('Internal server error.'));
    }
  }
}

function* handleLikeForReply(action) {
  try {
    const { reply_id, consultation_id } = action.payload;
    const requestPayload = {
      id: reply_id
    };

    const { data } = yield call(processRequest, `/likes/like_for_reply`, 'POST', requestPayload);

    if(data.count) {
      yield put(commentsActions.likeForReplySuccess(data.count));
      yield put(consultationsActions.getConsultation(consultation_id));
    } else if(data.error_message) {
      yield put(commentsActions.likeForReplyError(data.error_message));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status } = response || {};
    const { error_messages } = data || {};
    const keys = Object.keys(error_messages);
    const errorMessage = error_messages[keys[0]];
    const message = error_messages && `${ keys[0] } ${ errorMessage }`;

    if(status === 400) {
      yield put(commentsActions.likeForReplyError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess());
    } else {
      yield put(commentsActions.likeForReplyError('Internal server error.'));
    }
  }
}

function* handleReportForComment(action) {
  try {
    const { comment_id, body, consultation_id } = action.payload;
    const requestPayload = {
      report: { body }
    };

    const { data } = yield call(processRequest, `/reports/report_for_comment?id=${ comment_id }`, 'POST', requestPayload);

    if(data.message) {
      yield put(commentsActions.reportForCommentSuccess(data.message));
      yield put(consultationsActions.getConsultation(consultation_id));
    } else if(data.error_message) {
      yield put(commentsActions.reportForCommentError(data.error_message));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status } = response || {};
    const { error_messages } = data || {};
    const keys = Object.keys(error_messages);
    const errorMessage = error_messages[keys[0]];
    const message = error_messages && `${ keys[0] } ${ errorMessage }`;

    if(status === 400) {
      yield put(commentsActions.reportForCommentError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess());
    } else {
      yield put(commentsActions.reportForCommentError('Internal server error.'));
    }
  }
}

function* handleReportForReply(action) {
  try {
    const { reply_id, body } = action.payload;
    const requestPayload = {
      report: { body }
    };

    const { data } = yield call(processRequest, `/reports/report_for_reply?id=${ reply_id }`, 'POST', requestPayload);

    if(data.message) {
      yield put(commentsActions.reportForReplySuccess(data.message));
    } else if(data.error_message) {
      yield put(commentsActions.reportForReplyError(data.error_message));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status } = response || {};
    const { error_messages } = data || {};
    const keys = Object.keys(error_messages);
    const errorMessage = error_messages[keys[0]];
    const message = error_messages && `${ keys[0] } ${ errorMessage }`;

    if(status === 400) {
      yield put(commentsActions.reportForReplyError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess());
    } else {
      yield put(commentsActions.reportForReplyError('Internal server error.'));
    }
  }
}

function* handleEditComment(action) {
  try {
    const { comment: { id, consultation_id }, body } = action.payload;
    const requestPayload = {
      comment: { consultation_id, body }
    };

    const { data } = yield call(processRequest, `/comments/${ id }`, 'PUT', requestPayload);

    if(data.comment) {
      yield put(commentsActions.editCommentSuccess());
      yield put(reportsActions.getReports());
      yield put(consultationsActions.getConsultation(consultation_id));
    } else if(data.error_message) {
      yield put(commentsActions.editCommentError(data.error_message));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status } = response || {};
    const { error_messages } = data || {};
    const keys = Object.keys(error_messages);
    const errorMessage = error_messages[keys[0]];
    const message = error_messages && `${ keys[0] } ${ errorMessage }`;

    if(status === 400) {
      yield put(commentsActions.editCommentError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess());
    } else {
      yield put(commentsActions.editCommentError('Internal server error.'));
    }
  }
}

function* handleDeleteComment(action) {
  try {
    const { comment } = action.payload;

    const { data } = yield call(processRequest, `/comments/${ comment.id }`, 'DELETE');

    if(data.message || data.comment) {
      yield put(commentsActions.deleteCommentSuccess());
      yield put(reportsActions.getReports());
      yield put(consultationsActions.getConsultation(comment.consultation_id));
    } else if(data.error_message) {
      yield put(commentsActions.deleteCommentError(data.error_message));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status } = response || {};
    const { error_messages } = data || {};
    const keys = Object.keys(error_messages);
    const errorMessage = error_messages[keys[0]];
    const message = error_messages && `${ keys[0] } ${ errorMessage }`;

    if(status === 400) {
      yield put(commentsActions.deleteCommentError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess());
    } else {
      yield put(commentsActions.deleteCommentError('Internal server error.'));
    }
  }
}

function* handleEditReply(action) {
  try {
    const { reply: { id, comment_id, consultation_id }, body } = action.payload;
    const requestPayload = {
      reply: { comment_id, body }
    };

    const { data } = yield call(processRequest, `/replies/${ id }`, 'PUT', requestPayload);

    if(data.reply) {
      yield put(commentsActions.editReplySuccess());
      yield put(reportsActions.getReports());
      yield put(consultationsActions.getConsultation(consultation_id));
    } else if(data.error_message) {
      yield put(commentsActions.editReplyError(data.error_message));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status } = response || {};
    const { error_messages } = data || {};
    const keys = Object.keys(error_messages);
    const errorMessage = error_messages[keys[0]];
    const message = error_messages && `${ keys[0] } ${ errorMessage }`;

    if(status === 400) {
      yield put(commentsActions.editReplyError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess());
    } else {
      yield put(commentsActions.editReplyError('Internal server error.'));
    }
  }
}

function* handleDeleteReply(action) {
  try {
    const { reply } = action.payload;
    const { data } = yield call(processRequest, `/replies/${ reply.id }`, 'DELETE');

    if(data.message) {
      yield put(commentsActions.deleteReplySuccess());
      yield put(reportsActions.getReports());
      yield put(consultationsActions.getConsultation(reply.consultation_id));
    } else if(data.error_message) {
      yield put(commentsActions.deleteReplyError(data.error_message));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status } = response || {};
    const { error_messages } = data || {};
    const keys = Object.keys(error_messages);
    const errorMessage = error_messages[keys[0]];
    const message = error_messages && `${ keys[0] } ${ errorMessage }`;

    if(status === 400) {
      yield put(commentsActions.deleteReplyError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess());
    } else {
      yield put(commentsActions.deleteReplyError('Internal server error.'));
    }
  }
}

function* handleGetComment(action) {
  try {
    const { comment_id } = action.payload;

    const { data } = yield call(processRequest, `/comments/${ comment_id }`, 'GET');

    if(data.message || data.comment) {
      yield put(commentsActions.getCommentSuccess(data.comment));
    } else if(data.error_message) {
      yield put(commentsActions.getCommentError(data.error_message));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status } = response || {};
    const { error_messages } = data || {};
    const keys = Object.keys(error_messages);
    const errorMessage = error_messages[keys[0]];
    const message = error_messages && `${ keys[0] } ${ errorMessage }`;

    if(status === 400) {
      yield put(commentsActions.getCommentError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess());
    } else {
      yield put(commentsActions.getCommentError('Internal server error.'));
    }
  }
}

export function* watchCommentsSagas() {
  yield takeEvery(COMMENTS_ACTIONS.ADD_COMMENT, handleAddComment);
  yield takeEvery(COMMENTS_ACTIONS.ADD_REPLY, handleAddReply);
  yield takeEvery(COMMENTS_ACTIONS.LIKE_FOR_COMMENT, handleLikeForComment);
  yield takeEvery(COMMENTS_ACTIONS.LIKE_FOR_REPLY, handleLikeForReply);
  yield takeEvery(COMMENTS_ACTIONS.REPORT_FOR_COMMENT, handleReportForComment);
  yield takeEvery(COMMENTS_ACTIONS.REPORT_FOR_REPLY, handleReportForReply);
  yield takeEvery(COMMENTS_ACTIONS.EDIT_COMMENT, handleEditComment);
  yield takeEvery(COMMENTS_ACTIONS.DELETE_COMMENT, handleDeleteComment);
  yield takeEvery(COMMENTS_ACTIONS.EDIT_REPLY, handleEditReply);
  yield takeEvery(COMMENTS_ACTIONS.DELETE_REPLY, handleDeleteReply);
  yield takeEvery(COMMENTS_ACTIONS.GET_COMMENT, handleGetComment);
};