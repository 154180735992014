import React from 'react';
import { AuthHeader } from '../../';
import Paul_Ryan from '../../../imgs/Paul_Ryan.jpeg';
import Chrisna from '../../../imgs/Chrisna.jpg';

const AboutUs = () => (
  <main>
    <AuthHeader
      title='About Us'
      description=''
    />
    <div className='container text-container'>
      <p>GPTemplates.co.za was founded by Dr. Paul Ryan and Dr. Chrisna de Villiers, it launched in June 2020.</p>
      <p>Together Dr. Paul Ryan, an Irish trained General Practitioner and the Therapeutic Lead for the Irish College of General Practitioners and Dr. Chrisna de Villiers, a South African trained General Practitioner currently living and working in Ireland, developed more than 200 consultation templates on a spectrum of different conditions presenting in general practice. The aim of GPTemplates is to save at least 3 minutes per consultation, adding an average of 90 minutes of consultation time to the working day (32 extra billable hours a month). These templates include the history, examination, treatment suggestions and additional information needed for thorough consultations with day to day cases in General Practice. They were reviewed by a team of GPs working in General Practice.</p>
      <p>Before relocating to Ireland in 2015, Chrisna worked in South Africa in rural and private medicine, spent a year focussing on Obstetrics and Gynaecology and was the team lead of a Physical Rehab practice in Bloemfontein. She has a passion for Women's Health and her focus is a holistic, empathetic approach to both patients and their families.</p>
      {/* <p>Paul and Chrisna are extremely grateful to all the GPs whose valuable contributions made this website possible.</p> */}
      {/* <p>If you need to contact us for any reason, please email us at <a href='mailto:support@gptemplates.co.za'>support@gptemplates.co.za</a>. We would love to hear from you.</p> */}
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}>
          <img src={ Paul_Ryan } alt='Paul_Ryan' />
        </div>
        <div style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}>
          <img src={ Chrisna } alt='Chrisna' />
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <p style={{margin: 'auto'}}>Dr. Paul Ryan</p>
        <p style={{margin: 'auto'}}>Dr. Chrisna de Villiers</p>
      </div>
      <p>Paul and Chrisna are extremely grateful to all the GPs whose valuable contributions made this website possible.</p>
      <p>Contact us for any enquiries at: <a href='mailto:support@gptemplates.co.za'>support@gptemplates.co.za</a></p>
      <p>We would love to hear from you.</p>
    </div>
  </main>
);

export default AboutUs;