import React from 'react';

const FormContainer = ({ children, classStyle, style = {} }) => (
  <section className={`sec-auth ${ classStyle }`} style={ style }>
    <div className="container">
      <div className="form-wrapper">
        { children }
      </div>
    </div>
  </section>
);

export default FormContainer;