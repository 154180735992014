import { NUGGETS_ACTIONS, INITIAL_STATE } from '../constants';
import { sortByLetter } from '../services/Helpers';

export default function nuggetsReducer(state = INITIAL_STATE.NUGGETS_STATE, action) {
  const { nuggets, meta, total_pages, error } = action.payload || {};

  switch(action.type) {
    case NUGGETS_ACTIONS.GET_NUGGETS: {
      return {
        ...state,
        meta,
      }
    }
    case NUGGETS_ACTIONS.GET_NUGGETS_SUCCESS: {
      const sortedNuggets = sortByLetter(nuggets);
      return {
        ...state,
        nuggets,
        sortedNuggets,
        meta: {
          ...state.meta,
          total_pages,
        },
      };
    }
    case NUGGETS_ACTIONS.GET_NUGGETS_NEXT_PAGE_SUCCESS: {
      const sortedNuggets = sortByLetter([...state.nuggets, ...nuggets]);
      return {
        ...state,
        nuggets: [...state.nuggets, ...nuggets],
        sortedNuggets,
        meta: {
          ...state.meta,
          total_pages,
        },
      };
    }
    case NUGGETS_ACTIONS.GET_NUGGETS_ERROR: {
      return {
        ...state,
        error,
      }
    }
    default:
      return state;
  }
};
