import React, { Component } from 'react';
import _ from 'lodash';
import { AuthHeader, FormContainer, ChangePasswordForm } from '../../';
import { PASSWORD } from '../../../constants';

class ChangePassword extends Component {
  constructor() {
    super();

    this.state = {
      old_password: '',
      password: '',
      password_confirmation: '',
      errors: {}
    };
  }

  handleOldPassword = event => {
    const old_password = event.target.value;

    this.setState({ old_password });
  }

  handlePassword = event => {
    const password = event.target.value;

    this.setState({ password });
  }

  handlePasswordConfirmation = event => {
    const password_confirmation = event.target.value;

    this.setState({ password_confirmation });
  }

  onSubmit = event => {
    event.preventDefault();

    const { old_password, password, password_confirmation } = this.state;
    const { authActions: { changePassword } } = this.props;
    let errors = {};

    if(!old_password || old_password === '') {
      errors.old_password = 'Please enter your old password';
    }
    if(!password || password === '') {
      errors.password = 'Please enter your password.';
    } else if(!PASSWORD.test(password)) {
      errors.password = 'Password must be more than 7 characters & contain at least one number & one capital letter.';
    }
    if(password && password_confirmation === '') {
      errors.password_confirmation = 'Please confirm password.';
    } else if(password && password_confirmation && password !== password_confirmation) {
      errors.password = ' ';
      errors.password_confirmation = 'Password do not match.';
    }
    if(_.isEmpty(errors)) {
      changePassword(old_password, password, password_confirmation);
    } else {
      this.setState({ errors });
    }
  }

  render() {
    const { authState: { errorMessage } } = this.props;

    return (
      <main>
        <AuthHeader
          title='Change Password'
          description="Must be more than 7 characters & contain at least one number & one capital letter."
        />
        <FormContainer classStyle='newpass'>
          { errorMessage &&
            <span className='main-auth-error'>{ errorMessage }</span>
          }
          <ChangePasswordForm
            onSubmit={ this.onSubmit }
            handleOldPassword={ this.handleOldPassword }
            handlePassword={ this.handlePassword }
            handlePasswordConfirmation={ this.handlePasswordConfirmation }
            { ...this.state }
          />
        </FormContainer>
      </main>
    );
  }
}

export default ChangePassword;