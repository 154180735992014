import { connect } from 'react-redux';
import { Nuggets } from '../../components';
import { bindActionCreators } from 'redux';
import { nuggestActions } from '../../actions';

function mapStateToProps({
  nuggetsState: {meta, sortedNuggets},
  authState: {user},
}) {
  return {
    values: sortedNuggets,
    isAllLoaded: (meta.page >= meta.total_pages),
    meta,
    user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getNuggets: bindActionCreators(nuggestActions.getNuggets, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Nuggets);
