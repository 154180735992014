import { connect } from 'react-redux';
import { Activity } from '../../components';
import { bindActionCreators } from 'redux';
import { activityActions, additionalDataActions } from '../../actions';

function mapStateToProps(state) {
  return {
    values: state.activityState.values,
    isAllLoaded: (state.activityState.meta.page >= state.activityState.meta.total_pages),
    additionalData: state.additionalDataState
  }
}

function mapDispatchToProps(dispatch) {
  return {
    activityActions: bindActionCreators(activityActions, dispatch),
    additionalDataActions: bindActionCreators(additionalDataActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
