import { SUGGESTION_ACTIONS, INITIAL_STATE } from '../constants';

export default function suggestionReducer(state = INITIAL_STATE.SUGGESTION_STATE, action) {
  const { error, suggestionSentSuccess } = action.payload || {};

  switch(action.type) {
    case SUGGESTION_ACTIONS.SEND_SUGGESTION_SUCCESS: {
      return {
        ...state,
        suggestionSentSuccess,
        error: null
      };
    }
    case SUGGESTION_ACTIONS.CLEAR_SUCCESS_MESSAGE:
      return {
        ...state,
        suggestionSentSuccess: '',
      };
    case SUGGESTION_ACTIONS.SEND_SUGGESTION_ERROR: {
      return {
        ...state,
        error
      };
    }
    default:
      return state;
  }
}