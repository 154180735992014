import { CURRENT_USER_ACTIONS } from '../constants';

export const getCurrentUser = () => {
  return {
    type: CURRENT_USER_ACTIONS.GET_CURRENT_USER,
  };
}

export const getCurrentSuccess = (value) => {
  return {
    type: CURRENT_USER_ACTIONS.GET_CURRENT_USER_SUCCESS,
    payload: { value }
  };
}

export const getCurrentUserError = (error = true) => {
  return {
    type: CURRENT_USER_ACTIONS.GET_CURRENT_USER_ERROR,
    payload: { error }
  };
}

export const updateCurrentUser = (updateData = {}) => {
  return {
    type: CURRENT_USER_ACTIONS.UPDATE_CURRENT_USER,
    payload: { updateData }
  };
}

export const updateCurrentUserSuccess = message => {
  return {
    type: CURRENT_USER_ACTIONS.UPDATE_CURRENT_USER_SUCCESS,
    payload: { message }
  };
}

export const updateCurrentUserError = (errors = {}) => {
  return {
    type: CURRENT_USER_ACTIONS.UPDATE_CURRENT_USER_ERROR,
    payload: { errors }
  };
}
