import React from 'react';
import { ROUTES } from '../../../constants';
import { AuthHeader, FormContainer, AuthenticationButton } from '../../';

const ForgotPasswordConfirmation = ({ history }) => (
  <main>
    <AuthHeader
      title='Password Recovery Confirmation'
      description='You should receive an email shortly to enable you to reset your password. If you don’t receive it, please check your Spam folder.'
    />
    <FormContainer classStyle='recovery'>
      <AuthenticationButton title='Ok' onClick={ () => history.push(ROUTES.HOME) } />
    </FormContainer>
  </main>
);

export default ForgotPasswordConfirmation;