import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CategoryDetail } from '../../components';
import { globalActions, categoriesActions, consultationsActions } from '../../actions';

const mapStateToProps = state => {
  return {
    category: state.categoriesState.category,
    categories: state.categoriesState.categories,
    subcategories: state.categoriesState.subcategories,
    user: state.authState.user,
    consultationSuccessMessage: state.consultationsState.successMessage
  };
}

const mapDispatchToProps = dispatch => {
  return {
    globalActions: bindActionCreators(globalActions, dispatch),
    categoriesActions: bindActionCreators(categoriesActions, dispatch),
    consultationsActions: bindActionCreators(consultationsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDetail);
