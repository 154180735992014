import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import CloseIcon from '../../../imgs/close-icon.svg';

const Disclaimer = ({ onHideDisclaimer, disclaimer }) => (
  <div className="consult-disclaimer">
    <div className="container">
      <div className="container-small">
        <div className="discl-container">
          <div className="discl-title">DISCLAIMER</div>
          <div className="discl-btn">
            <button className="like-text" onClick={ onHideDisclaimer }>
              <img src={ CloseIcon } alt="close icon" />
            </button>
          </div>
          <div className="discl-message">
            <p>{ ReactHtmlParser(disclaimer) }</p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Disclaimer;