import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../constants';
import MessageIcon from '../../../imgs/message-icon.svg';
import ImageIcon from '../../../imgs/image-icon.svg';
import PDFIcon from '../../../imgs/pdf-icon.svg';
import IconClip from '../../../imgs/icon-clip.svg';
import CloseIcon from '../../../imgs/close-icon.svg';

const WriteAComment = ({
  onFocusComment,
  activeCommentPlaceholder,
  removeAttachment,
  attachments,
  addAttachment,
  body,
  onWriteComment,
  addComment,
  showRules,
  placeholder,
  buttonText,
  hideCommentInput,
  showCloseButton,
  error
}) => (
  <div className="comments--write-new">
    <div className={`write-new-card ${ activeCommentPlaceholder ? 'active' : '' }`}>
      { activeCommentPlaceholder ? (
        <div className="write-rightnow">
          { error &&
            <span className='main-auth-error' style={{ marginLeft: '0', textAlign: 'center' }}>{ error }</span>
          }
          { showCloseButton &&
            <button className="like-text" style={{ float: 'right' }} onClick={ hideCommentInput }>
              <div className="reply-container">
                <div className="icon-block">
                  <img src={ CloseIcon } alt="reply this comment" />
                </div>
              </div>
            </button>
          }
          <div className="rightnow--text">
            <textarea
              cols="30"
              rows="3"
              onChange={ onWriteComment }
              value={ body }
              placeholder={ placeholder ? placeholder : 'Add comment' }
            />
          </div>
          { attachments && attachments.length > 0 && 
            <div className="rightnow--attachments">
              { attachments.map((attachment, index) =>
                <div key={ index } className="attachment-single">
                  <img src={ attachment.attachment_content_type === 'application/pdf' ? PDFIcon : ImageIcon } alt="file icon" />
                  <span>{ attachment.name }</span>
                  <button className="like-text" onClick={ () => removeAttachment(index) }>
                    <img src={ CloseIcon } alt="delete" />
                  </button>
                </div>
              )}
            </div>
          }
          <div className="rightnow--buttons">
            { attachments.length === 3 ?
              <div className="clip-block">
                <button className="like-text" disabled>
                  <div>
                    <img src={ IconClip } alt="add file"/>
                    <span>3 maximum</span>
                  </div>
                </button>
              </div> :
              <div className="clip-block">
                <input
                  id='add-file'
                  type='file'
                  style={{ display: 'none' }}
                  className="like-text"
                  onChange={ addAttachment }
                />
                <div>
                  <label htmlFor='add-file'>
                    <img src={ IconClip } alt="add file"/>jpg png pdf doc docx
                  </label>
                </div>
              </div>
            }
            <div className="add-block">
              <button className='like-text' onClick={ addComment }>{ buttonText ? buttonText : 'Add comment' }</button>
            </div>
          </div>
        </div>
       ) : (
          <div className="write-placeholder" onClick={ onFocusComment }>
            <img src={ MessageIcon } alt="write comment"/>
            <span>Add comment...</span>
          </div>
       )
      }
    </div>
    <p className='rules'>
      { showRules && activeCommentPlaceholder &&
        <p>Feel free to suggest amendments or improvements by adding your comment below. Please familiarise yourself with our comments policy <Link target='_blank' to={ ROUTES.TERMS_AND_CONDITIONS }>here</Link> before taking part.</p>
      }
    </p>
  </div>
);

export default WriteAComment;