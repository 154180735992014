import { takeEvery, put, call, select  } from 'redux-saga/effects';
import { ACTIVITY_ACTIONS } from '../constants';
import { activityActions } from '../actions';
import { processRequest } from '../services/Api';

const PER_PAGE = 10;

function* handleGetActivityRequest(page) {
  return yield call(processRequest, `/feeds/index?page=${page}&per_page=${PER_PAGE}`, 'GET');
}

function* handleGetActivity({ action, search }) {
  try {
    const { meta, values } = yield select(state => state.activityState);
    const { page } = meta;
    const newPage = page + 1;

    const { data } = yield call(handleGetActivityRequest, newPage);

    const newValues = [...values, ...data.feeds];
    const newMeta = { ...meta, page: newPage, total_pages: data.total_pages };

    yield put(activityActions.getActivitySuccess(newValues, newMeta));
  } catch (e) {
    console.error(e);
    yield put(activityActions.getActivityError(true))
  }
}

export function* watchActivitySagas() {
  yield takeEvery(ACTIVITY_ACTIONS.GET_ACTIVITY, handleGetActivity);
};
