import React, { Component } from 'react';
import { sortReports, getSortedReportsMonthKeys, formatedDateForReports } from '../../../services/Helpers';
import { MonthWrapper } from '../../';

class Reports extends Component {
  componentWillMount() {
    this.props.reportsActions.getReports();
  }

  render() {
    const { reports, globalActions } = this.props;
    const sortedReports = sortReports(reports);
    const monthes = getSortedReportsMonthKeys(sortedReports);

    return (
      <main>
        <section className='sec-activity report'>
          <div className="container">
            <div className="activity-title">
              <p>Reports</p>
            </div>
            <div className="activity-list">
              <div className="list-container">
                { monthes && monthes.map(month => (
                  <MonthWrapper
                    key={ month }
                    reports={ sortedReports }
                    month={ formatedDateForReports(month) }
                    monthkey={ month }
                    globalActions={ globalActions }
                  />
                ))
                }
              </div>
              {/* <div className="list-footer">
                <button className="btn large filled">SHOW MORE</button>
              </div> */}
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default Reports;