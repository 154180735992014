import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { authActions } from '../../actions';
import { ChangePassword } from '../../components';

const mapStateToProps = state => {
  return {
    authState: state.authState
  };
}

const mapDispatchToProps = dispatch => {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);