import React, { Component } from 'react';
import { ListItem } from '../../ui/Activity';
import { debounce } from 'lodash';
import { fetchAdditionalData } from '../../../services';

class Activity extends Component {

  debouncedLoadMore = debounce(this.onLoadMore, 300);
  processedFeeds = [];
  feedableTypeAdditionalDataProcessor = {
    User: null,
    Comment: this._processAdditionalDataComment,
    Like: this._processAdditionalDataLike,
    Reply: this._processAdditionalDataReply
  }

  componentDidMount() {
    this.debouncedLoadMore();
    this._loadAdditionalData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.values.length !== this.props.values.length) {
      this._loadAdditionalData();
    }
  }

  componentWillUnmount() {
    this.props.activityActions.resetActivity();
  }

  onLoadMore() {
    this.props.activityActions.getActivity();
  }

  fetchAdditionalData = (relation, id) => {
    const { additionalData } = this.props;

    return fetchAdditionalData(additionalData, relation, id);
  }

  _loadAdditionalData = () => {
    const { values } = this.props;
    const { feedableTypeAdditionalDataProcessor, processedFeeds } = this;
    values.forEach(value => {
      const processFun = feedableTypeAdditionalDataProcessor[value.feedable_type]

      if (processedFeeds.includes(value.id) || !processFun) {
        return;
      }

      processFun.bind(this)(value);
    });
  }

  _processAdditionalDataLike(value) {
    const { additionalDataActions } = this.props;
    const feedableValue = value.feedable;

    if (!feedableValue || !feedableValue.likeable || !feedableValue.likeable.consultation_id) {
      return;
    }

    additionalDataActions.getAdditionalData('consultations', feedableValue.likeable.consultation_id);
    this.processedFeeds.push(value.id);
  }

  _processAdditionalDataComment(value) {
    const { additionalDataActions } = this.props;
    const feedableValue = value.feedable;

    if (!feedableValue || !feedableValue.consultation_id) {
      return;
    }

    additionalDataActions.getAdditionalData('consultations', feedableValue.consultation_id);
    this.processedFeeds.push(value.id);
  }

  _processAdditionalDataReply(value) {
    const { additionalDataActions } = this.props;
    const feedableValue = value.feedable;

    if (!feedableValue || !feedableValue.comment_id) {
      return;
    }

    additionalDataActions.getAdditionalData('comments', feedableValue.comment_id);
    this.processedFeeds.push(value.id);
  }

  _renderActivityList = () => {
    const { values } = this.props;
    return values.map(value => (
      <ListItem key={value.id} value={value} fetchAdditionalData={this.fetchAdditionalData} />
    ));
  }

  render() {
    return (
      <section className="sec-activity">
        <div className="container">
          <div className="activity-title">
            <p>LATEST ACTIVITY</p>
          </div>
          <div className="activity-list">
            <div className="list-container">
              {this._renderActivityList()}
            </div>

            {!this.props.isAllLoaded && <div className="list-footer">
              <button onClick={this.debouncedLoadMore.bind(this)} className="btn large filled">SHOW MORE</button>
            </div>}
          </div>
        </div>
      </section>
    );
  }
}

export default Activity;
