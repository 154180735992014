import { CATEGORIES_ACTIONS } from '../constants';

export const getCategories = search => {
  return {
    type: CATEGORIES_ACTIONS.GET_CATEGORIES,
    payload: { search }
  };
}

export const getCategoriesSuccess = categories => {
  return {
    type: CATEGORIES_ACTIONS.GET_CATEGORIES_SUCCESS,
    payload: { categories }
  };
}

export const getCategoriesError = error => {
  return {
    type: CATEGORIES_ACTIONS.GET_CATEGORIES_ERROR,
    payload: { error }
  };
}

export const getCategory = (id, search) => {
  return {
    type: CATEGORIES_ACTIONS.GET_CATEGORY,
    payload: { id, search }
  };
}

export const getCategorySuccess = (category_id, subcategories) => {
  return {
    type: CATEGORIES_ACTIONS.GET_CATEGORY_SUCCESS,
    payload: { category_id, subcategories }
  };
}

export const getCategoryError = error => {
  return {
    type: CATEGORIES_ACTIONS.GET_CATEGORY_ERROR,
    payload: { error }
  };
}

export const changeCategory = () => {
  return {
    type: CATEGORIES_ACTIONS.CHANGE_CATEGORY
  };
}

export const createCategory = title => {
  return {
    type: CATEGORIES_ACTIONS.CREATE_CATEGORY,
    payload: { title }
  };
}

export const createCategorySuccess = () => {
  return {
    type: CATEGORIES_ACTIONS.CREATE_CATEGORY_SUCCESS
  };
}

export const createCategoryError = error => {
  return {
    type: CATEGORIES_ACTIONS.CREATE_CATEGORY_ERROR,
    payload: { error }
  };
}

export const deleteCategory = category_id => {
  return {
    type: CATEGORIES_ACTIONS.DELETE_CATEGORY,
    payload: { category_id }
  };
}

export const deleteCategorySuccess = () => {
  return {
    type: CATEGORIES_ACTIONS.DELETE_CATEGORY_SUCCESS
  };
}

export const deleteCategoryError = error => {
  return {
    type: CATEGORIES_ACTIONS.DELETE_CATEGORY_ERROR,
    payload: { error }
  };
}

export const editCategory = (category_id, title) => {
  return {
    type: CATEGORIES_ACTIONS.EDIT_CATEGORY,
    payload: { category_id, title }
  };
}

export const editCategorySuccess = category => {
  return {
    type: CATEGORIES_ACTIONS.EDIT_CATEGORY_SUCCESS,
    payload: { category }
  };
}

export const editCategoryError = error => {
  return {
    type: CATEGORIES_ACTIONS.EDIT_CATEGORY_ERROR,
    payload: { error }
  };
}

export const clearCategory = () => {
  return {
    type: CATEGORIES_ACTIONS.CLEAR_CATEGORY
  };
}