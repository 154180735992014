import keymirror from 'keymirror';

export default keymirror({
  GET_CONSULTATION: null,
  GET_CONSULTATION_SUCCESS: null,
  GET_CONSULTATION_ERROR: null,
  SAVE_CONSULTATION: null,
  SAVE_CONSULTATION_SUCCESS: null,
  SAVE_CONSULTATION_ERROR: null,
  EDIT_CONSULTATION: null,
  EDIT_CONSULTATION_SUCCESS: null,
  EDIT_CONSULTATION_ERROR: null,
  CLEAR_CONSULTATION: null,
  DELETE_CONSULTATION: null,
  DELETE_CONSULTATION_SUCCESS: null,
  DELETE_CONSULTATION_ERROR: null,
  CLEAR_MESSAGE: null,
  INCREMENT_SPONSOR_CLICK: null,
  INCREMENT_CONSULTATION_VIEW: null,
  GET_UPDATED_CONSULTATIONS: null,
  GET_UPDATED_CONSULTATIONS_SUCCESS: null,
  GET_UPDATED_CONSULTATIONS_NEXT_PAGE_SUCCESS: null,
  GET_UPDATED_CONSULTATIONS_ERROR: null,
});
