import React from 'react';
import { IMAGE_URL } from '../../../constants';
import BackArrow from '../../../imgs/back-arrow.svg';

const ConsultationHeader = ({
  consultation,
  goBack,
  user,
  onSponsorClick,
  handleCopy,
}) => (
  <div className="navigation-back sponsor">
    <button className='btn' onClick={ goBack }>
      <div className="back-container">
        <div className="back-arrow">
          <img src={ BackArrow } alt="Back arrow"/>
        </div>
        <div className="back-text">
          <p className="blue">{ consultation.category.title }</p>
        </div>
      </div>
    </button>
    { consultation.is_sponsored && user.user_type !== 'practice_nurse' &&
      <a
        href={ `${ consultation.sponsor_url ? consultation.sponsor_url : '' }` }
        target='_blank'
        rel="noopener noreferrer"
        onClick={ onSponsorClick }
      >
        <div className="navigation-sponsor">
          <div className='sponsor-title'>
            <p>{ 'Kindly supported by: ' }</p>
          </div>
          <div className='support-logo'>
            <img src={ `${ IMAGE_URL }${ consultation.sponsor_logo_url }` } alt="support logo"/>
          </div>
        </div>
      </a>
    }
    <button
      className='btn small outlined btn-add-category'
      onClick={handleCopy}
      type='button'
    >
      Copy to clipboard
    </button>
  </div>
);

export default ConsultationHeader;