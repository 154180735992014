import React from 'react';
import { Input, AuthenticationButton } from '../../';

const ContactUsForm = ({onSubmit, errors, handleText, text, message}) => (
  <form onSubmit={ onSubmit }>
    { message && <p className='profile-updated-success-message'>{ message }</p> }
    <div style={{textAlign: 'center'}}>
      <p style={{ margin: '10px 0' }}>Help Us Improve This Template</p>
      <span>If you think this template can be improved, please send us your suggestion and we will get back to you.</span>
    </div>
    <Input
      type='text'
      placeholder='Write here...'
      styleClass={ errors && errors.text ? 'error' : '' }
      error={ errors && errors.text && errors.text }
      onInputChange={ handleText }
      value={ text }
    />
    <AuthenticationButton title='Submit' />
  </form>
);

export default ContactUsForm;