import { COMMENTS_ACTIONS, INITIAL_STATE } from '../constants';

export default function commentsReducer(state = INITIAL_STATE.COMMENTS_STATE, action) {
  const { error, successMessage, comment } = action.payload || {};

  switch(action.type) {
    case COMMENTS_ACTIONS.ADD_COMMENT_SUCCESS:
    case COMMENTS_ACTIONS.ADD_REPLY_SUCCESS: {
      return {
        ...state,
        successMessage
      };
    }
    case COMMENTS_ACTIONS.REPORT_FOR_COMMENT_SUCCESS:
    case COMMENTS_ACTIONS.REPORT_FOR_REPLY_SUCCESS: {
      return {
        ...state,
        reportedSuccess: 'Report sent successfully. Our moderators will check your report soon. Thank you.'
      };
    }
    case COMMENTS_ACTIONS.ADD_COMMENT_ERROR:
    case COMMENTS_ACTIONS.ADD_REPLY_ERROR:
    case COMMENTS_ACTIONS.LIKE_FOR_COMMENT_ERROR:
    case COMMENTS_ACTIONS.LIKE_FOR_REPLY_ERROR:
    case COMMENTS_ACTIONS.REPORT_FOR_COMMENT_ERROR:
    case COMMENTS_ACTIONS.REPORT_FOR_REPLY_ERROR:
    case COMMENTS_ACTIONS.EDIT_COMMENT_ERROR:
    case COMMENTS_ACTIONS.DELETE_COMMENT_ERROR:
    case COMMENTS_ACTIONS.EDIT_REPLY_ERROR:
    case COMMENTS_ACTIONS.DELETE_REPLY_ERROR:
    case COMMENTS_ACTIONS.GET_COMMENT_ERROR: {
      return {
        ...state,
        error
      };
    }
    case COMMENTS_ACTIONS.CLEAR_SUCCESS_MESSAGE: {
      return {
        ...state,
        successMessage: '',
        reportedSuccess: ''
      };
    }
    case COMMENTS_ACTIONS.GET_COMMENT_SUCCESS: {
      return {
        ...state,
        comment
      };
    }
    default:
      return state;
  }
};