import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../constants';
import joinedIcon from '../../../imgs/joined-icon.svg';
import commendedIcon from '../../../imgs/comment-icon.svg';
import likedIcon from '../../../imgs/heart-icon.svg';
import repliedIcon from '../../../imgs/reply-icon.svg';

import { DATE_CONSTANTS, IMAGE_URL, USER_TYPES, GP_TYPES } from '../../../constants';

const ItemComponents = {
  add_avatar: function NewUser() {
    return (
      <div className="item-activity">
        <div className="item-activity--icon">
          <img src={joinedIcon} alt="icon" />
        </div>
        <div className="item-activity--text">
          <span>{'Has added a profile picture.'}</span>
        </div>
      </div>
    )
  },
  joined_user: function NewUser() {
    return (
      <div className="item-activity">
        <div className="item-activity--icon">
          <img src={joinedIcon} alt="icon" />
        </div>
        <div className="item-activity--text">
          <span>{'Has joined GP Consult'}</span>
        </div>
      </div>
    )
  },
  create_comment: function NewComment({ value }) {
    const title = value.consultation_title ? value.consultation_title.replace(/<\/?[^>]+(>|$)/g, '') : '';

    return (
      <div className="item-activity">
        <div className="item-activity--icon">
          <img src={commendedIcon} alt="icon" />
        </div>
        <div className="item-activity--text">
          <span>Commented on <Link to={ ROUTES.CONSULTATION_DETAILS.replace(':id', value.consultation_id) }>{ title }</Link></span>
        </div>
      </div>
    );
  },
  liked_comment: function NewLike({ value }) {
    const title = value.likable ? value.likable.consultation_title.replace(/<\/?[^>]+(>|$)/g, '') : '';

    return (
      <div className="item-activity">
        <div className="item-activity--icon">
          <img src={likedIcon} alt="icon" />
        </div>
        <div className="item-activity--text">
          <span>Liked a comment on <Link to={ ROUTES.CONSULTATION_DETAILS.replace(':id', value.likable.consultation_id) }>{ title }</Link></span>
        </div>
      </div>
    );
  },
  liked_reply: function NewLike({ value }) {
    const title = value.likable ? value.likable.consultation_title.replace(/<\/?[^>]+(>|$)/g, '') : '';

    return (
      <div className="item-activity">
        <div className="item-activity--icon">
          <img src={likedIcon} alt="icon" />
        </div>
        <div className="item-activity--text">
          <span>Liked a reply on <Link to={ ROUTES.CONSULTATION_DETAILS.replace(':id', value.likable.consultation_id) }>{ title }</Link></span>
        </div>
      </div>
    );
  },
  create_reply: function NewReply({ value }) {
    const title = value.consultation_title ? value.consultation_title.replace(/<\/?[^>]+(>|$)/g, '') : '';

    return (
      <div className="item-activity">
        <div className="item-activity--icon">
          <img src={repliedIcon} alt="icon" />
        </div>
        <div className="item-activity--text">
          <span>Replied to a comment on <Link to={ ROUTES.CONSULTATION_DETAILS.replace(':id', value.consultation_id) }>{ title }</Link></span>
        </div>
      </div>
    );
  }
}

function ItemWrapper({ user, children, timestamp }) {

  const { avatar_url, first_name, last_name, gp_type, user_type, county } = user;
  const fullName = `${first_name} ${last_name}`;
  const gpType = GP_TYPES[gp_type];
  const userType = USER_TYPES[user_type];

  return (
    <div className="list-item">
      <div className="item-ava">
        <div className="user-avatar avatar">
          { avatar_url ?
            <div className="user-avatar">
              <img src={ `${IMAGE_URL}${avatar_url}` } alt="User profile" />
            </div> :
            <div className="no-avatar avatar">
              <span>{ first_name.charAt(0) }</span><span>{ last_name.charAt(0)  }</span>
            </div>
          }
        </div>
      </div>

      <div className="item-profile">
        <div className="item-profile--user">
          <p>{fullName}</p>
          <span>{gpType ? gpType : userType}</span>
        </div>
        <div className="item-profile--time">
          <p>{timestamp.format(DATE_CONSTANTS.DATE_UI)}</p>
          <span>{timestamp.format(DATE_CONSTANTS.TIME_FORMAT)}</span>
        </div>
      </div>

      {children}
    </div>
  );
}

export { ItemComponents, ItemWrapper };
