import React from 'react';
import moment from 'moment';
import { ItemWrapper, ItemComponents } from './ItemComponents';

function ListItem({ value, fetchAdditionalData }) {

  if (!value || !value.user) {
    return;
  }

  const { feed_type, user, created_at } = value;
  const createdAt = moment(created_at);
  const Component = ItemComponents[feed_type];

  if (!Component) {
    return null;
  }

  return (
    <ItemWrapper
      user={user}
      timestamp={createdAt}
    >
      <Component value={value.feedable} fetchAdditionalData={fetchAdditionalData} />
    </ItemWrapper>
  );
}

export { ListItem };
