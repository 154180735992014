import React from 'react';
import Modal from 'react-modal';
import { MenuCross, AuthenticationButton } from '../../';

const DeleteCategoryConfirmationModal = ({ isOpenDeleteCategoryModal, toggleDeleteCategoryModal, onCategoryDelete }) => (
  <Modal
    isOpen={ isOpenDeleteCategoryModal }
    onRequestClose={ toggleDeleteCategoryModal }
    contentLabel="DeleteCategoryModal"
    className='modal'
    appElement={ document.getElementById('app') }
  >
    <div className='modal-container'>
      <span className='title'>Are you sure you want to delete this category</span>
      <button
        className="modal-close-btn"
        tabIndex="1"
        onClick={ toggleDeleteCategoryModal }
      >
        { MenuCross }
      </button>
      <AuthenticationButton
        title='Delete'
        onClick={ onCategoryDelete }
      />
    </div>
  </Modal>
);

export default DeleteCategoryConfirmationModal;