import React from 'react';
import { Link } from 'react-router-dom';
import LogoCompact from '../../../imgs/GPC_vector.svg';
import { ROUTES } from '../../../constants';

const AuthRoutes = ({ user, pathname, logout, toggleMenu }) => (
  user ? (
    <div className="auth-routes">
      <div className="hlinks-wrapper">
        <Link onClick={ toggleMenu } to={ ROUTES.HOME } className={ `hlink text ${ pathname === ROUTES.HOME ? 'active' : '' }` }>Dashboard</Link>
        <Link to={ ROUTES.UPDATES } onClick={ toggleMenu } className={ `hlink text ${ pathname === ROUTES.UPDATES ? 'active' : '' }` }>Updates</Link>
        <Link to={ ROUTES.NUGGETS } onClick={ toggleMenu } className={ `hlink text ${ pathname === ROUTES.NUGGETS ? 'active' : '' }` }>Nuggets</Link>
        {/* { user && user.role !== 'pharma' &&
          <Link onClick={ toggleMenu } to={ ROUTES.ACTIVITY } className={ `hlink text ${ pathname === ROUTES.ACTIVITY ? 'active' : '' }` }>Activity</Link>
        } */}
        {/* <Link onClick={ toggleMenu } to={ ROUTES.PAYMENTS } className={ `hlink text ${ pathname === ROUTES.PAYMENTS ? 'active' : '' }` }>Payments</Link> */}
        { user && user.role !== 'pharma' &&
          <Link onClick={ toggleMenu } to={ ROUTES.PROFILE } className={ `hlink text ${ pathname === ROUTES.PROFILE ? 'active' : '' }` }>Profile</Link>
        }
      </div>
      <span className="btn small outlined" onClick={ logout }>log out</span>
    </div>
  ) : (
    <div className="auth-routes">
      <img src={ LogoCompact } alt="GPC Logo" />
      <Link onClick={ toggleMenu } to={ ROUTES.SIGN_IN } className="btn large outlined">sign in</Link>
      <p>Don't have an account? <Link onClick={ toggleMenu } to={ ROUTES.SIGN_UP } className="text">Create Account</Link></p>
    </div>
  )
);

export default AuthRoutes;