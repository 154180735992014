import { GLOBAL_ACTIONS } from '../constants';

export const toggleMenu = isOpen => {
  return {
    type: GLOBAL_ACTIONS.TOGGLE_MENU,
    payload: { isOpen }
  };
}

export const toggleFooter = showFooter => {
  return {
    type: GLOBAL_ACTIONS.TOGGLE_FOOTER,
    payload: { showFooter }
  };
}

export const clearLocalStorage = () => {
  return {
    type: GLOBAL_ACTIONS.CLEAR_LOCAL_STORAGE
  };
}

export const toggleAddCategoryModal = isOpenCategoryModal => {
  return {
    type: GLOBAL_ACTIONS.TOGGLE_ADD_CATEGORY_MODAL,
    payload: { isOpenCategoryModal }
  };
}

export const toggleDeleteCategoryModal = (isOpenDeleteCategoryModal, category) => {
  return {
    type: GLOBAL_ACTIONS.TOGGLE_DELETE_CATEGORY_MODAL,
    payload: { isOpenDeleteCategoryModal, category }
  };
}

export const toggleEditCategoryModal = (isOpenEditCategoryModal, category) => {
  return {
    type: GLOBAL_ACTIONS.TOGGLE_EDIT_CATEGORY_MODAL,
    payload: { isOpenEditCategoryModal, category }
  };
}

export const toggleReportModal = (isOpenReportModal, type, reportable) => {
  return {
    type: GLOBAL_ACTIONS.TOGGLE_REPORT_MODAL,
    payload: { isOpenReportModal, type, reportable }
  };
}

export const toggleEditCommentReplyModal = (isOpenEditCommentReplyModal, commentReply, type) => {
  return {
    type: GLOBAL_ACTIONS.TOGGLE_EDIT_COMMENT_REPLY_MODAL,
    payload: { isOpenEditCommentReplyModal, commentReply, type }
  };
}

export const toggleDeleteCommentReplyModal = (isOpenDeleteCommentReplyModal, commentReply, type) => {
  return {
    type: GLOBAL_ACTIONS.TOGGLE_DELETE_COMMENT_REPLY_MODAL,
    payload: { isOpenDeleteCommentReplyModal, commentReply, type }
  };
}

export const toggleDeleteConsultationModal = (isOpenDeleteConsultationModal, consultation) => {
  return {
    type: GLOBAL_ACTIONS.TOGGLE_DELETE_CONSULTATION_MODAL,
    payload: { isOpenDeleteConsultationModal, consultation }
  };
}