import React from 'react';
import { Input, AuthenticationButton, Select } from '../../';
import { COUNTIES, USER_TYPE, GP_TYPE } from '../../../constants';

const SignUpConfirmationForm = ({
  onSubmit,
  errors,
  handleIMC,
  imc,
  practice_name,
  handlePracticeName,
  handleSelect,
  isGP,
  isLocum,
  isPracticeNurse,
  handleNMBI,
  nmbi
}) => (
  <form onSubmit={ onSubmit }>
    <Select
      name='county'
      options={ COUNTIES }
      handleSelect={ handleSelect }
      styleClass={ errors && errors.county ? 'error' : '' }
      error={ errors && errors.county && errors.county }
    />
    <Select
      name='user_type'
      options={ USER_TYPE }
      handleSelect={ handleSelect }
      styleClass={ errors && errors.user_type ? 'error' : '' }
      error={ errors && errors.user_type && errors.user_type }
    />
    { isGP &&
      <Select
        name='gp_type'
        options={ GP_TYPE }
        handleSelect={ handleSelect }
        styleClass={ errors && errors.gp_type ? 'error' : '' }
        error={ errors && errors.gp_type && errors.gp_type }
      />
    }
    { isPracticeNurse ?
      <Input
        type='text'
        placeholder='NMBI'
        styleClass={ errors && errors.nmbi ? 'error' : '' }
        error={ errors && errors.nmbi && errors.nmbi }
        onInputChange={ handleNMBI }
        value={ nmbi }
      /> :
      <Input
        type='text'
        placeholder='IMC #'
        styleClass={ errors && errors.imc ? 'error' : '' }
        error={ errors && errors.imc && errors.imc }
        onInputChange={ handleIMC }
        value={ imc }
      />
    }
    { isGP && !isLocum &&
      <Input
        type='text'
        placeholder='Practice name'
        styleClass={ errors && errors.practice_name ? 'error' : '' }
        error={ errors && errors.practice_name && errors.practice_name }
        onInputChange={ handlePracticeName }
        value={ practice_name }
        maxLength={ 20 }
      />
    }
    <AuthenticationButton title='create account' />
  </form>
);

export default SignUpConfirmationForm;
