import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { globalActions } from '../../actions';
import { Home } from '../../components';

const mapStateToProps = state => {
  return {
    globalState: state.globalState
  };
}

const mapDispatchToProps = dispatch => {
  return {
    globalActions: bindActionCreators(globalActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);