import { REPORTS_ACTIONS, INITIAL_STATE } from '../constants';

export default function reportsReducer(state = INITIAL_STATE.REPORTS_STATE, action) {
  const { reports, error, reviewedReports } = action.payload || {};

  switch(action.type) {
    case REPORTS_ACTIONS.GET_REPORTS_SUCCESS: {
      return {
        ...state,
        reports,
        error: null
      };
    }
    case REPORTS_ACTIONS.GET_REVIEWED_REPORTS_SUCCESS: {
      return {
        ...state,
        reviewedReports,
        error: null
      };
    }
    case REPORTS_ACTIONS.GET_REPORTS_ERROR:
    case REPORTS_ACTIONS.GET_REVIEWED_REPORTS_ERROR:
    case REPORTS_ACTIONS.REVIEW_REPORT_ERROR: {
      return {
        ...state,
        error
      };
    }
    default:
      return state;
  }
}