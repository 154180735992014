import { COMMENTS_ACTIONS } from '../constants';

export const addComment = commentData => {
  return {
    type: COMMENTS_ACTIONS.ADD_COMMENT,
    payload: { commentData }
  };
}

export const addCommentSuccess = successMessage => {
  return {
    type: COMMENTS_ACTIONS.ADD_COMMENT_SUCCESS,
    payload: { successMessage }
  };
}

export const addCommentError = error => {
  return {
    type: COMMENTS_ACTIONS.ADD_COMMENT_ERROR,
    payload: { error }
  };
}

export const addReply = replyData => {
  return {
    type: COMMENTS_ACTIONS.ADD_REPLY,
    payload: { replyData }
  };
}

export const addReplySuccess = successMessage => {
  return {
    type: COMMENTS_ACTIONS.ADD_REPLY_SUCCESS,
    payload: { successMessage }
  };
}

export const addReplyError = error => {
  return {
    type: COMMENTS_ACTIONS.ADD_REPLY_ERROR,
    payload: { error }
  };
}

export const likeForComment = (comment_id, consultation_id) => {
  return {
    type: COMMENTS_ACTIONS.LIKE_FOR_COMMENT,
    payload: { comment_id, consultation_id }
  };
}

export const likeForCommentSuccess = () => {
  return {
    type: COMMENTS_ACTIONS.LIKE_FOR_COMMENT_SUCCESS
  };
}

export const likeForCommentError = error => {
  return {
    type: COMMENTS_ACTIONS.LIKE_FOR_COMMENT_ERROR,
    payload: { error }
  }
}

export const likeForReply = (reply_id, consultation_id) => {
  return {
    type: COMMENTS_ACTIONS.LIKE_FOR_REPLY,
    payload: { reply_id, consultation_id }
  };
}

export const likeForReplySuccess = () => {
  return {
    type: COMMENTS_ACTIONS.LIKE_FOR_REPLY_SUCCESS
  };
}

export const likeForReplyError = error => {
  return {
    type: COMMENTS_ACTIONS.LIKE_FOR_REPLY_ERROR,
    payload: { error }
  };
}

export const reportForComment = (comment_id, body, consultation_id) => {
  return {
    type: COMMENTS_ACTIONS.REPORT_FOR_COMMENT,
    payload: { comment_id, body, consultation_id }
  };
}

export const reportForCommentSuccess = () => {
  return {
    type: COMMENTS_ACTIONS.REPORT_FOR_COMMENT_SUCCESS
  };
}

export const reportForCommentError = error => {
  return {
    type: COMMENTS_ACTIONS.REPORT_FOR_COMMENT_ERROR,
    payload: { error }
  };
}

export const reportForReply = (reply_id, body, consultation_id) => {
  return {
    type: COMMENTS_ACTIONS.REPORT_FOR_REPLY,
    payload: { reply_id, body, consultation_id }
  };
}

export const reportForReplySuccess = () => {
  return {
    type: COMMENTS_ACTIONS.REPORT_FOR_REPLY_SUCCESS
  };
}

export const reportForReplyError = error => {
  return {
    type: COMMENTS_ACTIONS.REPORT_FOR_REPLY_ERROR,
    payload: { error }
  };
}

export const clearSuccessMessage = () => {
  return {
    type: COMMENTS_ACTIONS.CLEAR_SUCCESS_MESSAGE
  };
}

export const editComment = (comment, body) => {
  return {
    type: COMMENTS_ACTIONS.EDIT_COMMENT,
    payload: { comment, body }
  };
}

export const editCommentSuccess = () => {
  return {
    type: COMMENTS_ACTIONS.EDIT_COMMENT_SUCCESS
  };
}

export const editCommentError = error => {
  return {
    type: COMMENTS_ACTIONS.EDIT_COMMENT_ERROR,
    payload: { error }
  };
}

export const deleteComment = comment => {
  return {
    type: COMMENTS_ACTIONS.DELETE_COMMENT,
    payload: { comment }
  };
}

export const deleteCommentSuccess = () => {
  return {
    type: COMMENTS_ACTIONS.DELETE_COMMENT_SUCCESS
  };
}

export const deleteCommentError = error => {
  return {
    type: COMMENTS_ACTIONS.DELETE_COMMENT_ERROR,
    payload: { error }
  };
}

export const editReply = (reply, body) => {
  return {
    type: COMMENTS_ACTIONS.EDIT_REPLY,
    payload: { reply, body }
  };
}

export const editReplySuccess = () => {
  return {
    type: COMMENTS_ACTIONS.EDIT_REPLY_SUCCESS
  };
}

export const editReplyError = error => {
  return {
    type: COMMENTS_ACTIONS.EDIT_REPLY_ERROR,
    payload: { error }
  };
}

export const deleteReply = reply => {
  return {
    type: COMMENTS_ACTIONS.DELETE_REPLY,
    payload: { reply }
  };
}

export const deleteReplySuccess = () => {
  return {
    type: COMMENTS_ACTIONS.DELETE_REPLY_SUCCESS
  };
}

export const deleteReplyError = error => {
  return {
    type: COMMENTS_ACTIONS.DELETE_REPLY_ERROR,
    payload: { error }
  };
}

export const getComment = comment_id => {
  return {
    type: COMMENTS_ACTIONS.GET_COMMENT,
    payload: { comment_id }
  };
}

export const getCommentSuccess = comment => {
  return {
    type: COMMENTS_ACTIONS.GET_COMMENT_SUCCESS,
    payload: { comment }
  };
}

export const getCommentError = error => {
  return {
    type: COMMENTS_ACTIONS.GET_COMMENT_ERROR,
    payload: { error }
  };
}