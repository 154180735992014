import { takeEvery, takeLatest, put, call, select  } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { CURRENT_USER_ACTIONS, LOCAL_STORAGE, ROUTES } from '../constants';
import { currentUserActions, authActions } from '../actions';
import { processRequest } from '../services/Api';

// LOAD CURRENT USER

function* currentUserRequest(id) {
  return yield call(processRequest, `/users/${id}`, 'GET');
}

function* handleGetCurrentUser() {
  try {

    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE.USER));

    if (!user || !user.id) {
      return;
    }

    const { data } = yield call(currentUserRequest, user.id);


    if (!data.user) {
      return;
    }

    yield put(currentUserActions.getCurrentSuccess(data.user));
    yield put(currentUserActions.getCurrentUserError(false));
  } catch (e) {
    const { response} = e || {};
    const { data, status } = response || {};
    const { error_messages } = data || {};

    if(status === 400) {
      const keys = Object.keys(error_messages);
      const errorMessage = error_messages[keys[0]];
      const message = error_messages && `${ keys[0] } ${ errorMessage }`;

      yield put(currentUserActions.getCurrentUserError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess());
      yield put(replace(ROUTES.HOME));
    } else {
      yield put(currentUserActions.getCurrentUserError('Internal server error.'));
    }
  }
}

// UPDATE CURRENT USER

function* updateCurrentUserRequest(id, data) {
  return yield call(processRequest, `/users/${id}`, 'PUT', data, false);
}

function formatUpdateData(data) {
  const keys = Object.keys(data);

  const formData = new FormData();

  keys.forEach(field => {
    formData.append(`user[${field}]`, data[field]);
  });

  processUpdateData(data, formData);
  return formData;
}

function processUpdateData(data, formData) {
  if (!data.avatar) {
    formData.delete(`user[avatar]`);
  }

  if (data.user_type !== 'gp' && data.user_type !== 'practice_nurse') {
    formData.delete('user[gp_type]', '');
    formData.delete('user[practice_name]', '');
  }
  if (data.user_type === 'practice_nurse') {
    formData.delete('user[gp_type]', '');
  }
}

function* handleUpdateCurrentUser({ payload }) {
  try {
    const { updateData } = payload;
    const currentUser = yield select(state => state.currentUserState.value);

    if (!currentUser) {
      return;
    }

    const formData = formatUpdateData(updateData);

    const { data } = yield call(updateCurrentUserRequest, currentUser.id, formData);

    yield put(currentUserActions.getCurrentSuccess(data.user));
    yield put(authActions.loginSuccess(data.user));

    yield put(currentUserActions.updateCurrentUserSuccess('Profile has been updated.'));
    yield put(currentUserActions.updateCurrentUserError());
  } catch (e) {
    const { response } = e;
    const { data } = response || {};
    const { error_messages } = data || {};
    let errorMessage = '';

    if (response.status === 400) {
      const keys = Object.keys(error_messages);
      const error = error_messages[keys[0]];

      if(keys[0] === 'practice_name') {
        errorMessage = { practice_name: `Practice Name ${ error }` };
      } else {
        const error = error_messages[keys[0]];

        errorMessage = error && { [keys[0]]: `${ keys[0] } ${ error }` };
      }

      yield put(currentUserActions.updateCurrentUserError(errorMessage));
    } else {
      console.error(e);
    }
  }
}

export function* watchCurrentUserSagas() {
  yield takeEvery(CURRENT_USER_ACTIONS.GET_CURRENT_USER, handleGetCurrentUser);
  yield takeLatest(CURRENT_USER_ACTIONS.UPDATE_CURRENT_USER, handleUpdateCurrentUser);
};
