import { ACTIVITY_ACTIONS } from '../constants';

export const getActivity = (search = {}) => ({
  type: ACTIVITY_ACTIONS.GET_ACTIVITY,
  payload: { search }
});

export const getActivitySuccess = (values, meta) => ({
  type: ACTIVITY_ACTIONS.GET_ACTIVITY_SUCCESS,
  payload: { values, meta }
});

export const getActivityError = (error) => ({
  type: ACTIVITY_ACTIONS.GET_ACTIVITY_ERROR,
  payload: { error }
});

export const resetActivity = (error) => ({
  type: ACTIVITY_ACTIONS.RESET,
  payload: { error }
});
