import React from 'react';

const AuthenticationButton = ({ title, onClick, loading }) => (
  <div className="form-row center">
    <button
      type={ onClick ? '' : 'submit'}
      onClick={ onClick ? onClick : () => {} }
      className="btn large filled"
      disabled={ loading }
    >
      { title }
    </button>
  </div>
);

export default AuthenticationButton;