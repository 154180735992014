import React from 'react';
import { IMAGE_URL } from '../../../constants';
import ReactHtmlParser from 'react-html-parser';
import ImageIcon from '../../../imgs/image-icon.svg';
import PDFIcon from '../../../imgs/pdf-icon.svg';

const ConsultationInfo = ({ consultation, title, className, description, name, user, onSponsorClick, attachmentName, attachments }) => (
  title ? (
    <div className="editor-title">
      <p className='title-text consultatation-details-title'>{ ReactHtmlParser(title) }</p>
      { consultation.is_sponsored && user.user_type !== 'practice_nurse' &&
        <a
          href={ `${ consultation.sponsor_url ? consultation.sponsor_url : '' }` }
          target='_blank'
          rel="noopener noreferrer"
          onClick={ onSponsorClick }
        >
          <div className="navigation-sponsor">
            <div className='support-logo'>
              <img src={ `${ IMAGE_URL }${ consultation.sponsor_logo_url }` } alt="support logo" />
            </div>
            <div className='sponsor-title'>
              <p>{ 'Supported by: ' }</p>
            </div>
          </div>
        </a>
      }
    </div>
  ) : ( description &&
    <div style={{marginBottom: '40px'}}>
      <div className={`editor-${ className } viewer-w`}>
        <div className="editor-w--label">{ name }</div>
        <div className="viewer--viewer">
          { ReactHtmlParser(description) }
        </div>
      </div>
      <div className="consultation--attachments">
        { attachments &&
          attachments.map(attachment => (
            attachmentName === attachment.section ?
            <a key={ attachment.id } href={ `${ IMAGE_URL }${ attachment.attachment_url }` } target="_blank" rel="noopener noreferrer">
              <div className="attachment-single">
                <img src={ attachment.document_file_name.match(/^.+\.(([pP][dD][fF]))$/) ? PDFIcon : ImageIcon } alt="file icon" />
                <span>{ attachment.document_file_name }</span>
              </div>
            </a> : null
          )
        )}
      </div>
    </div>
  )
);

export default ConsultationInfo;