import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import BackgroundImageSmall from '../../../imgs/g-hero-small.png';
import BackgroundImage from '../../../imgs/g-hero.png';
import GrowthIcon from '../../../imgs/likes-icon.svg';
import LikesIcon from '../../../imgs/growth-icon.svg';
import GWhatSmall from '../../../imgs/g-what-small.png';
import GWhat from '../../../imgs/g-what.png';
import { ROUTES } from '../../../constants';
import {
  ColabIcon,
} from '../../';

class Home extends Component {
  componentWillMount() {
    const { globalActions: { toggleFooter } } = this.props;

    toggleFooter(true);
  }

  componentDidMount() {
    ReactTooltip.show(this.fooRef)
  }

  componentWillUnmount() {
    const { globalActions: { toggleFooter } } = this.props;

    toggleFooter(false);
  }

  onCloseMenu = () => {
    const { globalActions: { toggleMenu } } = this.props;

    toggleMenu(false);
  }

  render() {
    return (
      <main className="main-content">
          <section className='sec-guest-hero'>
            <div className="hero-img limit-width">
              <picture>
                <source media="(max-width: 575px)" srcSet={BackgroundImageSmall}/>
                <source media="(min-width: 576px)" srcSet={BackgroundImage}/>
                <img src={BackgroundImage} alt="doc"/>
              </picture>
              <div className="hero-text">
                <div className="container">
                  <div className="hero-text-container">
                    <div className="text-container">
                      <p>Consultation Templates</p>
                      <p>For Conditions Commonly</p>
                      <p>Seen in General Practice</p>
                    </div>
                    <div className="create-acc-container" style={{position: 'relative'}}>
                      {/* <span>Over 200 templates covered - 3,000 Rand per annum</span> */}
                      {/* <span style={{fontSize: '16px', marginTop: '24px', paddingLeft: '15px'}}>{`After that, an annual subscription costs R 4000/year.`}</span> */}
                      <Link ref={ref => this.fooRef = ref} data-for='happyFace' data-tip='tooltip' onClick={ this.onCloseMenu } to={ROUTES.SIGN_UP} className="btn shadow large filled" style={{maxWidth: '270px'}}>JOIN US - IT'S FREE</Link>
                      <span>Are you a General Practitioner?</span>
                      {/* <div className="tooltip">
                        <span style={{color: 'white'}}>Due to the pandemic, GPTemplates will be free to use until March 1st 2021</span>
                      </div> */}
                      {/* <ReactTooltip offset={{top: 13, left: 0}} id="happyFace" type="dark" className="show" style={{position: 'absolute !important', top: 0, left: 0, opacity: 1}}>
                        <span style={{color: 'white'}}>Due to the pandemic, GPTemplates will be free to use until March 1st 2021</span>
                      </ReactTooltip> */}
                    </div>
                  </div>
                </div>
                {/* <div className="navigation-sponsor dashboard-sponsor">
                  <div className='sponsor-title'>
                    <p style={{ margin: '10px 0' }}>{ 'Supported by: ' }</p>
                  </div>
                  <div className='support-logo'>
                    <img src={ medprotec } alt="support logo" style={{ maxWidth: '150px' }} />
                  </div>
                </div> */}
              </div>
            </div>
            <div className="hero-features">
              <div className="limit-width">
                <div className="container">
                  <div className="features-container">
                    <p className="features-heading">FEATURES</p>
                    <div className="features-wrapper">
                      <div className="feature-card">
                        <div className="card-img">
                          {ColabIcon}
                        </div>
                        <div className="card-text">
                          <p>Consultation Templates</p>
                          <p>Over 200 consultation templates updated by a community of GP peers</p>
                        </div>
                      </div>
                      <div className="feature-card">
                        <div className="card-img">
                          <img src={GrowthIcon} alt="img"/>
                        </div>
                        <div className="card-text">
                          <p>Management Plans</p>
                          <p>Management plans for conditions based on best practice suggestions from GPs </p>
                        </div>
                      </div>
                      <div className="feature-card">
                        <div className="card-img">
                          <img src={LikesIcon} alt="img"/>
                        </div>
                        <div className="card-text">
                          <p>Improve Notekeeping Quality</p>
                          <p>Improve the quality of your note keeping</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <section className="sec-guest-what">
            <div className="container">
              <div className="what-container">
                <div className="what--image">
                  <picture>
                    <source media="(max-width: 400px)" srcSet={GWhatSmall}/>
                    <source media="(min-width: 401px)" srcSet={GWhat}/>
                    <img src={GWhat} alt="comp"/>
                  </picture>
                </div>
                <div className="what--text">
                  <p>What is</p>
                  <p>GPTemplates?</p>
                  <p>
                    GPTemplates.co.za is an online service offering a range of consultation templates for conditions commonly seen in General Practice.
                    <br/><br/>
                    These templates have been created by a number of General Practitioners working in General Practice and can be used to help document the symptoms, signs and management plans of these conditions.
                    <br/><br/>
                    GPTemplates.co.za is only open to General Practitioners. It will save you on average 3 minutes per consultation (up to 90 minutes per day) enabling you to see more of your patients.
                    <br/><br/>
                    It was founded by Dr. Paul Ryan who is an Irish trained General Practitioner and the Therapeutic Lead for the Irish College of General Practitioners and Dr. Chrisna de Villiers, a South African trained General Practitioner currently living and working in Ireland. It was launched in June 2020.
                  </p>
                  <Link onClick={ this.onCloseMenu } to={ROUTES.SIGN_UP} className="btn large filled shadow">JOIN US</Link>
                </div>

              </div>
            </div>
          </section>
    </main>
    )
  }
}

export default Home;