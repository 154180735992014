import React, { Component } from 'react';
import _ from 'lodash';
import { AuthHeader, FormContainer, SignUpConfirmationForm } from '../../';
import { GP_TYPE } from '../../../constants';

class SignUpConfirmation extends Component {
  constructor() {
    super();

    this.state = {
      imc: '',
      county: '',
      user_type: '',
      practice_name: '',
      nmbi: '',
      gp_type: GP_TYPE[0].value.toString(),
      avatar: null,
      avatarFile: null,
      errors: {}
    };
  }

  handleIMC = event => {
    const imc = event.target.value;

    this.setState({
      imc,
      errors: {}
    });
  }

  handleNMBI = event => {
    const nmbi = event.target.value;

    this.setState({
      nmbi,
      errors: {}
    });
  }

  handlePracticeName = event => {
    const practice_name = event.target.value;

    this.setState({
      practice_name,
      errors: {}
    });
  }

  handleSelect = event => {
    const target = event.target;

    this.setState({
      [target.name]: target.value,
      errors: {}
    });
  }

  onSubmit = event => {
    event.preventDefault();

    const { imc, county, user_type, gp_type, avatar, practice_name, nmbi } = this.state;
    const { authActions: { updateUser }, authState: { user } } = this.props;
    const isGP = user_type === '0';
    const isLocum = gp_type === '2';
    const isPracticeNurse = user_type === '2';
    let errors = {};

    if(!isPracticeNurse && (!imc || imc === '')) {
      errors.imc = 'IMC cant be blank.';
    }
    if(!county || county === '') {
      errors.county = 'County cant be blank.';
    }
    if(!user_type || user_type === '') {
      errors.user_type = 'User Type cant be blank.';
    }
    if(isGP && (!gp_type || gp_type === '')) {
      errors.gp_type = 'GP Type cant be blank.';
    }
    if(isGP && !isLocum && (!practice_name || practice_name === '')) {
      errors.practice_name = `Practice name can't be blank.`
    }
    if(isPracticeNurse && (!nmbi || nmbi === '')) {
      errors.nmbi = `NMBI can't be blank.`
    }

    if(_.isEmpty(errors)) {
      let newUser = {
        ...user,
        imc: !isPracticeNurse ? imc : null,
        county,
        user_type,
        gp_type: isGP ? gp_type : null,
        practice_name: isGP && !isLocum ? practice_name : null,
        avatar,
        nmbi: isPracticeNurse ? nmbi : null
      };

      updateUser(newUser);
    } else {
      this.setState({ errors });
    }
  }

  render() {
    const { imc, user_type, errors, practice_name, gp_type, nmbi } = this.state;
    const { authState: { errorMessage } } = this.props;
    const isGP = user_type === '0';
    const isLocum = gp_type === '2';
    const isPracticeNurse = user_type === '2';

    return (
      <main>
        <AuthHeader
          title='LAST STEP'
          description='Please complete your registration by filling profile information'
        />
        <FormContainer classStyle='confirm-user'>
          { errorMessage &&
            <span className='main-auth-error'>{ errorMessage }</span>
          }
          <SignUpConfirmationForm
            errorMessage={ this.props.authState.errorMessage }
            errors={ errors }
            onSubmit={ this.onSubmit }
            handleIMC={ this.handleIMC }
            imc={ imc }
            practice_name={ practice_name }
            handlePracticeName={ this.handlePracticeName }
            handleSelect={ this.handleSelect }
            isGP={ isGP }
            isLocum={ isLocum }
            isPracticeNurse={ isPracticeNurse }
            handleNMBI={ this.handleNMBI }
            nmbi={ nmbi }
          />
        </FormContainer>
      </main>
    )
  }
}

export default SignUpConfirmation;