import keymirror from 'keymirror';

export default keymirror({
  ADD_COMMENT: null,
  ADD_COMMENT_SUCCESS: null,
  ADD_COMMENT_ERROR: null,
  ADD_REPLY: null,
  ADD_REPLY_SUCCESS: null,
  ADD_REPLY_ERROR: null,
  LIKE_FOR_COMMENT: null,
  LIKE_FOR_COMMENT_SUCCESS: null,
  LIKE_FOR_COMMENT_ERROR: null,
  LIKE_FOR_REPLY: null,
  LIKE_FOR_REPLY_SUCCESS: null,
  LIKE_FOR_REPLY_ERROR: null,
  REPORT_FOR_COMMENT: null,
  REPORT_FOR_COMMENT_SUCCESS: null,
  REPORT_FOR_COMMENT_ERROR: null,
  REPORT_FOR_REPLY: null,
  REPORT_FOR_REPLY_SUCCESS: null,
  REPORT_FOR_REPLY_ERROR: null,
  CLEAR_SUCCESS_MESSAGE: null,
  EDIT_COMMENT: null,
  EDIT_COMMENT_SUCCESS: null,
  EDIT_COMMENT_ERROR: null,
  DELETE_COMMENT: null,
  DELETE_COMMENT_SUCCESS: null,
  DELETE_COMMENT_ERROR: null,
  EDIT_REPLY: null,
  EDIT_REPLY_SUCCESS: null,
  EDIT_REPLY_ERROR: null,
  DELETE_REPLY: null,
  DELETE_REPLY_SUCCESS: null,
  DELETE_REPLY_ERROR: null,
  GET_COMMENT: null,
  GET_COMMENT_SUCCESS: null,
  GET_COMMENT_ERROR: null
});