import React from 'react';
import Report from './Report';

const MonthWrapper = ({ month, reports, monthkey, globalActions }) => (
  <div className="month-wrapper">
    <div className="month-label">
      <p>{ month }</p>
    </div>
    { reports[monthkey] && reports[monthkey].map(report =>
      <Report
        key={ report.id }
        report={ report }
        globalActions={ globalActions }
      />
    )}
  </div>
);

export default MonthWrapper;