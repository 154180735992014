export default {
  MONTH_NAMES: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  DAYS: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  FULL_DATE_FORMAT: 'YYYY/MM/DD HH:mm:ss',
  SMALL_DATE_FORMAT: 'YYYY/MM/DD',
  INPUT_FORMAT: 'MM/DD/YYYY h:mm A',
  TIME_FORMAT: 'HH:mm',
  DATE_UI: 'MMM DD',
  PAYMENTS: 'DD/MM/YYYY'
}
