import React, { Component } from 'react';
import _ from 'lodash';
import { LargeResult, SmallResult } from '../../';
import { sortCategoriesAlphabetically, getLetters } from '../../../services/Helpers';
import BackArrow from '../../../imgs/back-arrow.svg';
import { ROUTES } from '../../../constants';

class CategoryDetail extends Component {
  constructor(props) {
      super(props);
  
      this.handleSearchCategory = _.debounce(props.categoriesActions.getCategories, 200)
      this.state = {
        selectedCategory: null,
        searchValue: ''
      };
  }

  componentWillMount() {
    const { categoriesActions, match: { params } } = this.props;

    categoriesActions.getCategories();
    categoriesActions.getCategory(params.id);
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.consultationSuccessMessage) {
      setTimeout(this.props.consultationsActions.clearMessage, 4000);
    }
  }

  onCategoryClick = selectedCategory => {
    this.props.history.push(ROUTES.CATEGORY_DETAIL.replace(':id', selectedCategory.id));
    this.props.categoriesActions.getCategory(selectedCategory.id);
    this.setState({ selectedCategory });
  }

  onSearch = event => {
    const searchValue = event.target.value;
    // const { category } = this.props;
    // const { selectedCategory } = this.state;
    // const id = selectedCategory ? selectedCategory.id : category.id;

    this.setState(() => ({ searchValue }), () => {
      this.handleSearchCategory(searchValue);
    });
  }

  onDeleteCategory = () => {
    const { globalActions, category } = this.props;

    globalActions.toggleDeleteCategoryModal(true, category);
  }

  onEditCategory = () => {
    const { globalActions, category } = this.props;

    globalActions.toggleEditCategoryModal(true, category);
  }

  onDeleteConsultation = (consultation_id, category_id) => {
    const consultation = { id: consultation_id, category_id };
    const { globalActions } = this.props;

    globalActions.toggleDeleteConsultationModal(true, consultation);
  }

  render() {
    const { categories, category, subcategories, user, consultationSuccessMessage } = this.props;
    const { selectedCategory, searchValue } = this.state;
    const sortCategories = categories.sort((category_a, category_b) => category_a.title.localeCompare(category_b.title));
    const sortedCategories = sortCategoriesAlphabetically(sortCategories);
    const letters = getLetters(sortedCategories);

    return (
      <section className="sec-category-content">
        <div className="container">
          <div className="navigation-back">
            <button className="back-container btn" onClick={ this.props.history.goBack }>
              <div className="back-arrow">
                <img src={ BackArrow } alt="Back arrow" />
              </div>
              <div className="back-text">
                <p className="blue">ALL CATEGORIES</p>
              </div>
            </button>
          </div>
          <div className="category-search-large">
            <div className="search-container">
              <input
                type="text"
                placeholder='Consultation Search'
                value={ searchValue }
                onChange={ this.onSearch }
              />
            </div>
          </div>
          { consultationSuccessMessage && <p className='profile-updated-success-message'>{ consultationSuccessMessage }</p> }
          <SmallResult
            category={ category }
            user={ user }
            onDeleteCategory={ this.onDeleteCategory }
            onEditCategory={ this.onEditCategory }
            onDeleteConsultation={ this.onDeleteConsultation }
          />
          <LargeResult
            categories={ sortedCategories }
            letters={ letters }
            selectedCategory={ selectedCategory || category }
            onCategoryClick={ this.onCategoryClick }
            subcategories={ subcategories }
            user={ user }
            onDeleteCategory={ this.onDeleteCategory }
            onEditCategory={ this.onEditCategory }
            onDeleteConsultation={ this.onDeleteConsultation }
          />
        </div>
      </section>
    );
  }
}

export default CategoryDetail;