import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { consultationsActions, commentsActions, globalActions } from '../../actions';
import { ConsultEditCreate } from '../../components';

const mapStateToProps = state => {
  return {
    consultation: state.consultationsState.consultation,
    category: state.categoriesState.category,
    error: state.consultationsState.error,
    user: state.authState.user,
    successMessage: state.commentsState.successMessage,
    comment: state.commentsState.comment
  };
}

const mapDispatchToProps = dispatch => {
  return {
    consultationsActions: bindActionCreators(consultationsActions, dispatch),
    commentsActions: bindActionCreators(commentsActions, dispatch),
    globalActions: bindActionCreators(globalActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultEditCreate);