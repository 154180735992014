import React, { Component } from 'react';
import _ from 'lodash';
import { AuthHeader, FormContainer, SignInForm } from '../../';
import { EMAIL } from '../../../constants';

class SignIn extends Component {
  constructor() {
    super();

    this.state = {
      email: '',
      password: '',
      showPassword: false,
      errors: {}
    };
  }

  handleEmail = event => {
    const email = event.target.value;

    this.setState({ email });
  }

  handlePassword = event => {
    const password = event.target.value;

    this.setState({ password });
  }

  onSubmit = event => {
    event.preventDefault();

    const { email, password } = this.state;
    const { authActions: { login } } = this.props;
    let errors = {};

    if(!email || email === '') {
      errors.email = 'Please enter your email.';
    } else if(!EMAIL.test(email)) {
      errors.email = 'Invalid email address.';
    }
    if(!password && password === '') {
      errors.password = 'Please enter your password.';
    }
    if(_.isEmpty(errors)) {
      login(email, password);
    } else {
      this.setState({ errors });
    }
  }

  onShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  componentWillUnmount() {
    this.props.authActions.enterUsernameAndPassword();
  }

  render() {
    const { authState: { errorMessage } } = this.props;

    return (
      <main>
        <AuthHeader
          title='Welcome Back'
          description='Please enter your details below:'
        />
        <FormContainer classStyle='signin'>
          { errorMessage &&
            <span className='main-auth-error' style={{ marginLeft: '0' }}>{ errorMessage }</span>
          }
          <SignInForm
            handleEmail={ this.handleEmail }
            handlePassword={ this.handlePassword }
            onSubmit={ this.onSubmit }
            onShowPassword={ this.onShowPassword }
            { ...this.state }
          />
        </FormContainer>
      </main>
    )
  }
}

export default SignIn;