import React, { Component } from 'react';
import Linkify from 'react-linkify';
import { getCommentCreatedDate } from '../../../services/Helpers';
import { USER_TYPES, GP_TYPES, IMAGE_URL } from '../../../constants';
import RepliedComment from './RepliedComment';
import WriteAComment from './WriteAComment';
import CloseIcon from '../../../imgs/close-icon.svg';
import AlertBlueIcon from '../../../imgs/alert-blue-icon.svg';
import ThreeDotsIcon from '../../../imgs/3dots-icon.svg';
import ImageIcon from '../../../imgs/image-icon.svg';
import PDFIcon from '../../../imgs/pdf-icon.svg';
import HeartIcon from '../../../imgs/heart-icon.svg';
import HeartMyIcon from '../../../imgs/heart-my-icon.svg';
import ReplyIcon from '../../../imgs/reply-arrow-icon.svg';

class Comment extends Component {
  constructor() {
    super();

    this.state = {
      isActiveReply: false,
      isDropdownOpened: false
    };
  }

  onReplyPress = () => {
    const { clearError } = this.props;

    clearError();
    this.setState({ isActiveReply: !this.state.isActiveReply });
  }

  addReply = () => {
    const { addReply, comment, body, clearError } = this.props;

    addReply(comment.id);

    if(body && body !== '') {
      clearError();
      this.setState({ isActiveReply: false });
    }
  }

  onLike = () => {
    const { onLike, comment } = this.props;

    onLike(comment.id, 'comment');
  }

  openReportModal = () => {
    const { openReportModal, comment } = this.props;

    openReportModal('comment', comment);
  }

  openDropdownMenu = () => {
    this.setState({ isDropdownOpened: !this.state.isDropdownOpened });
  }

  openEditCommentReplyModal = () => {
    const { comment, openEditCommentReplyModal } = this.props;

    openEditCommentReplyModal(comment, 'comment');

    this.setState({ isDropdownOpened: false });
  }

  onDeleteCommentReply = () => {
    const { comment, onDeleteCommentReply } = this.props;

    onDeleteCommentReply(comment, 'comment');

    this.setState({ isDropdownOpened: false });
  }

  showMoreReplies = () => {
    const { comment, showMoreReplies } = this.props;

    showMoreReplies(comment.id);
  }

  render() {
    const {
      user,
      comment,
      onLike,
      onFocusComment,
      removeAttachment,
      attachments,
      addAttachment,
      body,
      onWriteComment,
      addReply,
      openReportModal,
      openEditCommentReplyModal,
      onDeleteCommentReply,
      clearError,
      error,
      commentWithAllReplies
    } = this.props;
    const { isActiveReply, isDropdownOpened } = this.state;
    const replies = commentWithAllReplies && commentWithAllReplies.id === comment.id ? commentWithAllReplies.replies : comment.replies;

    return (
      <div className="comment-card">
        <div className={ `card-container ${ (comment.user && user.id === comment.user.id) || (user.role === 'admin') ? 'my' : '' }` }>
          <div className="comment--header">
            <div className="header-ava">
              { comment.user && comment.user.avatar_url ?
                <div className="user-avatar avatar">
                  <img src={ `${ IMAGE_URL }${ comment.user.avatar_url }` } alt="User profile" />
                </div> :
                <div className="no-avatar avatar">
                  <span>{ comment.user && comment.user.first_name.charAt(0) }</span><span>{ comment.user && comment.user.last_name.charAt(0)  }</span>
                </div>
              }
            </div>

            <div className="header-info">
              <p>{ `${ comment.user ? comment.user.first_name : '' } ${ comment.user ? comment.user.last_name : '' }` }</p>
              { comment.user &&
                <p>{ `${ comment.user.gp_type ? GP_TYPES[comment.user.gp_type] : USER_TYPES[comment.user.user_type] } in ${ comment.user.county }` }</p>
              }
              <p>{ getCommentCreatedDate(comment.created_at) }
              </p>
            </div>
            <div className={ `header-controls ${ isDropdownOpened ? 'open' : '' }` }>
              { (comment.user && comment.user.id === user.id) || user.role === 'admin' ?
                ( comment.user &&
                  <button className="like-text" onClick={ this.openDropdownMenu }>
                    <img src={ ThreeDotsIcon } alt="menu" />
                  </button>
                ) :
                <button onClick={ this.openReportModal } className="like-text">
                  <img src={ AlertBlueIcon } alt="complain on this message" />
                  <span>report</span>
                </button>
              }
              <ul className='comment-dropdown-menu'>
                <li onClick={ this.openEditCommentReplyModal }>Edit</li>
                <li onClick={ this.onDeleteCommentReply }>Delete</li>
              </ul>
            </div>
          </div>

          <div className="comment--body">
            <div className="text-output">
              <p style={{ margin: 0 }}>
                <Linkify
                  componentDecorator={ text =>
                    <a href={ text } target='_blank' rel='noopener noreferrer'>{ text }</a>
                  }
                >
                  { comment.body }
                </Linkify>
              </p>
            </div>
          </div>

          <div className="comment--attachments">
            { comment.attachments && comment.attachments.map(attachment =>
              <a key={ attachment.id } href={ `${ IMAGE_URL }${ attachment.attachment_url }` } target="_blank" rel="noopener noreferrer">
                <div className="attachment-single">
                  <img src={ attachment.document_file_name.match(/^.+\.(([pP][dD][fF]))$/) ? PDFIcon : ImageIcon } alt="file icon" />
                  <span>{ attachment.document_file_name }</span>
                </div>
              </a>
            )}
          </div>

          <div className="comment--footer">
            <button className="like-text" onClick={ this.onLike }>
              <div className={ `like-container ${ comment.is_liked ? 'like' : '' }` }>
                <div className="icon-block">
                  <img src={ comment.is_liked ? HeartMyIcon : HeartIcon } alt={ comment.is_liked ? "i like this" : "like icon" } />
                </div>
                <span>{ comment.likes_count }</span>
              </div>
            </button>

            <button className="like-text" onClick={ this.onReplyPress }>
              <div className="reply-container">
                <div className="icon-block">
                  <img src={ isActiveReply ? CloseIcon : ReplyIcon } alt="reply this comment" />
                </div>
                <span>{ isActiveReply ? 'cancel' : 'reply' }</span>
              </div>
            </button>
          </div>
        </div>
        <div className="comment--reply-container">
          { isActiveReply &&
            <WriteAComment
              comment_id={ comment.id }
              onFocusComment={ onFocusComment }
              activeCommentPlaceholder
              removeAttachment={ removeAttachment }
              attachments={ attachments }
              addAttachment={ addAttachment }
              body={ body }
              onWriteComment={ onWriteComment }
              addComment={ this.addReply }
              placeholder='Write your reply here'
              buttonText='ADD REPLY'
              error={ error }
            />
          }
          { replies &&
            replies.map(reply =>
              <RepliedComment
                key={ reply.id }
                comment_id={ comment.id }
                user={ user }
                reply={ reply }
                onLike={ onLike }
                onFocusComment={ onFocusComment }
                removeAttachment={ removeAttachment }
                attachments={ attachments }
                addAttachment={ addAttachment }
                body={ body }
                onWriteComment={ onWriteComment }
                addReply={ addReply }
                repliesToUser={ comment.user }
                openReportModal={ openReportModal }
                openEditCommentReplyModal={ openEditCommentReplyModal }
                onDeleteCommentReply={ onDeleteCommentReply }
                error={ error }
                clearError={ clearError }
              />
            )
          }
          { comment.repiles_count > 2 && replies.length <= 2 &&
            <button
              className="load-more"
              onClick={ this.showMoreReplies }
            >
              <span>SHOW ALL REPLIES</span>
            </button>
          }
        </div>
      </div>
    );
  }
}

export default Comment;