import { NUGGETS_ACTIONS } from '../constants';

export const getNuggets = (meta = {}) => ({
  type: NUGGETS_ACTIONS.GET_NUGGETS,
  payload: { meta }
});

export const getNuggetsSuccess = (nuggets, total_pages) => ({
  type: NUGGETS_ACTIONS.GET_NUGGETS_SUCCESS,
  payload: { nuggets, total_pages }
});

export const getNuggetsNextPageSuccess = (nuggets, total_pages) => ({
  type: NUGGETS_ACTIONS.GET_NUGGETS_NEXT_PAGE_SUCCESS,
  payload: { nuggets, total_pages }
});

export const getNuggetsError = (error) => ({
  type: NUGGETS_ACTIONS.GET_NUGGETS_ERROR,
  payload: { error }
});
