export default {
  AUTH_STATE: {
    errorMessage: null,
    successMessage: null,
    user: null
  },
  CATEGORIES_STATE: {
    categories: [],
    category: null,
    subcategories: null,
    error: false
  },
  CONSULTATIONS_STATE: {
    error: null,
    consultation: {},
    successMessage: '',
    consultations: [],
    meta: {
      page: 1,
      per_page: 10,
    },
  },
  COMMENTS_STATE: {
    error: null,
    comments: [],
    replies: [],
    successMessage: '',
    comment: {}
  },
  REPORTS_STATE: {
    reports: [],
    error: null
  },
  GLOBAL_STATE: {
    isOpen: false,
    showFooter: false,
    isOpenCategoryModal: false,
    isOpenDeleteCategoryModal: false,
    isOpenEditCategoryModal: false,
    isOpenReportModal: false,
    isOpenEditCommentReplyModal: false,
    isOpenDeleteCommentReplyModal: false,
    isOpenDeleteConsultationModal: false,
    type: '',
    reportable: null,
    category: null,
    commentReply: null,
    consultation: null
  },
  ACTIVITY_STATE: {
    values: [],
    meta: {
      page: 0,
      total_pages: 0
    },
    error: false
  },
  ADDITOONAL_DATA_STATE: {
    consultations: [],
    comments: [],
    progress: {}
  },
  CURRENT_USER: {
    value: null,
    error: false,
    updateErrors: null,
    message: ''
  },
  PAYMENT_STATE: {
    subscriptions: [],
    subscribed: false,
  },
  SUGGESTION_STATE: {
    error: null,
    suggestionSentSuccess: ''
  },
  NUGGETS_STATE: {
    nuggets: [],
    sortedNuggets: [],
    error: null,
    meta: {
      page: 1,
      per_page: 10,
      title: '',
      letter: '',
      total_pages: 1,
    },
  },
}
