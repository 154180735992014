import React from 'react';

const ModalInput = ({
  onInputChange,
  value,
  placeholder,
  styleClass,
  maxLength
}) => (
  <div className={ `form-row` }>
    <input
      type='text'
      placeholder={ placeholder }
      className={ styleClass }
      onChange={ onInputChange }
      value={ value }
      maxLength={ maxLength ? maxLength : 120 }
    />
  </div>
);

export default ModalInput;