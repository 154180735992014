import React from 'react';
import Modal from 'react-modal';
import { MenuCross, AuthenticationButton } from '../../';

const DeleteCommentReplyModal = ({ isOpenDeleteCommentReplyModal, toggleDeleteCommentReplyModal, onCategoryDelete, type, onDelete }) => (
  <Modal
    isOpen={ isOpenDeleteCommentReplyModal }
    onRequestClose={ toggleDeleteCommentReplyModal }
    contentLabel="AddCategoryModal"
    className='modal'
    appElement={ document.getElementById('app') }
  >
    <div className='modal-container'>
      <span className='title'>{ `Are you sure you want to delete this ${ type === 'comment' ? 'comment' : 'reply' }` }</span>
      <button
        className="modal-close-btn"
        tabIndex="1"
        onClick={ toggleDeleteCommentReplyModal }
      >
        { MenuCross }
      </button>
      <div style={{ display: 'flex' }}>
        <AuthenticationButton
          title='Yes'
          onClick={ onDelete }
        />
        <AuthenticationButton
          title='No'
          onClick={ toggleDeleteCommentReplyModal }
        />
      </div>
    </div>
  </Modal>
);

export default DeleteCommentReplyModal;