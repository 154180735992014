import { AUTH_ACTIONS } from '../constants';

export const login = (email, password) => {
  return {
    type: AUTH_ACTIONS.LOGIN,
    payload: { email, password }
  };
}

export const loginSuccess = user => {
  return {
    type: AUTH_ACTIONS.LOGIN_SUCCESS,
    payload: { user }
  };
}

export const loginError = errorMessage => {
  return {
    type: AUTH_ACTIONS.LOGIN_ERROR,
    payload: { errorMessage }
  };
}

export const signUp = user => {
  return {
    type: AUTH_ACTIONS.SIGN_UP,
    payload: { user }
  };
}

export const signUpSuccess = user => {
  return {
    type: AUTH_ACTIONS.SIGN_UP_SUCCESS,
    payload: { user }
  };
}

export const signUpError = errorMessage => {
  return {
    type: AUTH_ACTIONS.SIGN_UP_ERROR,
    payload: { errorMessage }
  };
}

export const logout = () => {
  return {
    type: AUTH_ACTIONS.LOGOUT
  };
}

export const logoutSuccess = () => {
  return {
    type: AUTH_ACTIONS.LOGOUT_SUCCESS
  };
}

export const logoutError = errorMessage => {
  return {
    type: AUTH_ACTIONS.LOGOUT_ERROR,
    payload: { errorMessage }
  };
}

export const enterUsernameAndPassword = (errorMessage, successMessage) => {
  return {
    type: AUTH_ACTIONS.ENTER_USERNAME_AND_PASSWORD_ERROR,
    payload: { errorMessage, successMessage }
  };
}

export const forgotPassword = email => {
  return {
    type: AUTH_ACTIONS.FORGOT_PASSWORD,
    payload: { email }
  };
}

export const forgotPasswordSuccess = successMessage => {
  return {
    type: AUTH_ACTIONS.FORGOT_PASSWORD_SUCCESS,
    payload: { successMessage }
  };
}

export const forgotPasswordError = errorMessage => {
  return {
    type: AUTH_ACTIONS.FORGOT_PASSWORD_ERROR,
    payload: { errorMessage }
  };
}

export const saveNewPassword = (password, password_confirmation, token) => {
  return {
    type: AUTH_ACTIONS.SAVE_NEW_PASSWORD,
    payload: { password, password_confirmation, token }
  };
}

export const saveNewPasswordSuccess = () => {
  return {
    type: AUTH_ACTIONS.SAVE_NEW_PASSWORD_SUCCESS
  };
}

export const saveNewPasswordError = errorMessage => {
  return {
    type: AUTH_ACTIONS.SAVE_NEW_PASSWORD_ERROR,
    payload: { errorMessage }
  };
}

export const changePassword = passwordData => {
  return {
    type: AUTH_ACTIONS.CHANGE_PASSWORD,
    payload: { passwordData }
  };
}

export const changePasswordSuccess = (user, successMessage) => {
  return {
    type: AUTH_ACTIONS.CHANGE_PASSWORD_SUCCESS,
    payload: { user, successMessage }
  };
}

export const changePasswordError = errorMessage => {
  return {
    type: AUTH_ACTIONS.CHANGE_PASSWORD_ERROR,
    payload: { errorMessage }
  };
}

export const getUserData = () => {
  return {
    type: AUTH_ACTIONS.GET_USER_DATA
  };
}

export const updateUser = user => {
  return {
    type: AUTH_ACTIONS.UPDATE_USER,
    payload: { user }
  };
}

export const updateUserSuccess = user => {
  return {
    type: AUTH_ACTIONS.UPDATE_USER_SUCCESS,
    payload: { user }
  };
}

export const updateUserError = errorMessage => {
  return {
    type: AUTH_ACTIONS.UPDATE_USER_ERROR,
    payload: { errorMessage }
  };
}

export const requestUserData = () => ({
  type: AUTH_ACTIONS.REQUEST_USER_DATA
});

export const requestUserDataSuccess = user => ({
  type: AUTH_ACTIONS.REQUEST_USER_DATA_SUCCESS,
  payload: { user }
});

export const requestUserDataError = error => ({
  type: AUTH_ACTIONS.REQUEST_USER_DATA_ERROR,
  payload: { error }
});