import React from 'react';
import { Input, AuthenticationButton } from '../../';

const NewPasswordForm = ({ onSubmit, errors, handlePassword, password, handlePasswordConfirmation, password_confirmation }) => (
  <form onSubmit={ onSubmit }>
    <Input
      type='password'
      placeholder='New Password'
      styleClass={ `${ errors && errors.password ? 'error' : '' }` }
      error={ errors && errors.password && errors.password }
      onInputChange={ handlePassword }
      value={ password }
      hideIcon
    />
    <Input
      type='password'
      placeholder='Confirm New Password'
      styleClass={ `${ errors && errors.password_confirmation ? 'error' : '' }` }
      error={ errors && errors.password_confirmation && errors.password_confirmation }
      onInputChange={ handlePasswordConfirmation }
      value={ password_confirmation }
      hideIcon
    />
    <AuthenticationButton title='confirm' />
  </form>
);

export default NewPasswordForm;