import React, { Component } from 'react';
import Linkify from 'react-linkify';
import { getCommentCreatedDate } from '../../../services/Helpers';
import { USER_TYPES, GP_TYPES, IMAGE_URL } from '../../../constants';
import WriteAComment from './WriteAComment';
import CloseIcon from '../../../imgs/close-icon.svg';
import AlertBlueIcon from '../../../imgs/alert-blue-icon.svg';
import ThreeDotsIcon from '../../../imgs/3dots-icon.svg';
import ImageIcon from '../../../imgs/image-icon.svg';
import PDFIcon from '../../../imgs/pdf-icon.svg';
import HeartIcon from '../../../imgs/heart-icon.svg';
import HeartMyIcon from '../../../imgs/heart-my-icon.svg';
import ReplyIcon from '../../../imgs/reply-arrow-icon.svg';

class RepliedComment extends Component {
  constructor() {
    super();

    this.state = {
      isActiveReply: false,
      isDropdownOpened: false
    };
  }

  onReplyPress = () => {
    this.props.clearError();
    this.setState({ isActiveReply: !this.state.isActiveReply });
  }

  addReply = () => {
    const { addReply, comment_id, body, clearError } = this.props;

    addReply(comment_id);

    if(body && body !== '') {
      clearError();
      this.setState({ isActiveReply: false });
    }
  }

  onLike = () => {
    const { onLike, reply } = this.props;

    if(!reply.is_liked) {
      onLike(reply.id);
    }
  }

  openReportModal = () => {
    const { openReportModal, reply } = this.props;

    openReportModal('reply', reply);
  }

  openDropdownMenu = () => {
    this.setState({ isDropdownOpened: !this.state.isDropdownOpened });
  }

  openEditCommentReplyModal = () => {
    const { reply, openEditCommentReplyModal } = this.props;

    openEditCommentReplyModal(reply, 'reply');

    this.setState({ isDropdownOpened: false });
  }

  onDeleteCommentReply = () => {
    const { reply, onDeleteCommentReply } = this.props;

    onDeleteCommentReply(reply, 'reply');

    this.setState({ isDropdownOpened: false });
  }

  render() {
    const {
      user,
      reply,
      onFocusComment,
      removeAttachment,
      attachments,
      addAttachment,
      body,
      onWriteComment,
      error
    } = this.props;
    const { isActiveReply, isDropdownOpened } = this.state;

    return (
      <div className={ `card-container ${ user.id === reply.user.id ? 'my' : '' }` }>
        <div className="comment--header">
          <div className="header-ava">
            { reply.user.avatar_url ?
              <div className="user-avatar avatar">
                <img src={ `${ IMAGE_URL }${ reply.user.avatar_url }` } alt="User profile" />
              </div> :
              <div className="no-avatar avatar">
                <span>{ reply.user.first_name.charAt(0) }</span><span>{ reply.user.last_name.charAt(0)  }</span>
              </div>
            }
          </div>

          <div className="header-info">
            <p>{ `${ reply.user.first_name } ${ reply.user.last_name }` }</p>
            <p>{ `${ reply.user.gp_type ? GP_TYPES[reply.user.gp_type] : USER_TYPES[reply.user.user_type] } in ${ reply.user.county }` }</p>
            <p>{ getCommentCreatedDate(reply.created_at) }
            {/* <span className="reply-to">{ `to ${ repliesToUser.first_name } ${ repliesToUser.last_name }` }</span> */}
            </p>
          </div>

          <div className={ `header-controls ${ isDropdownOpened ? 'open' : '' }` }>
            { user.id === reply.user.id || user.role === 'admin' ?
              <button className="like-text" onClick={ this.openDropdownMenu }>
                <img src={ ThreeDotsIcon } alt="menu" />
              </button> :
              <button onClick={ this.openReportModal } className="like-text">
                <img src={ AlertBlueIcon } alt="complain on this message" />
                <span>report</span>
              </button>
            }
            <ul className='comment-dropdown-menu'>
              <li onClick={ this.openEditCommentReplyModal }>Edit</li>
              <li onClick={ this.onDeleteCommentReply }>Delete</li>
            </ul>
            </div>
          </div>

          <div className="comment--body">
            {/* <p className="reply-to">{ `to ${ repliesToUser.first_name } ${ repliesToUser.last_name }` }</p> */}
            <div className="text-output">
              <p style={{ margin: 0 }}>
                <Linkify
                    componentDecorator={ text =>
                      <a href={ text } target='_blank' rel='noopener noreferrer'>{ text }</a>
                    }
                  >
                  { reply.body }
                </Linkify>
              </p>
            </div>
          </div>

          <div className="comment--attachments">
            { reply.attachments && reply.attachments.map(attachment =>
              <a key={ attachment.id } href={ `${ IMAGE_URL }${ attachment.attachment_url }` } target="_blank" rel="noopener noreferrer">
                <div className="attachment-single">
                  <img src={ attachment.attachment_url.match(/^.+\.(([pP][dD][fF]))$/) ? PDFIcon : ImageIcon } alt="file icon" />
                  <span>{ attachment.document_file_name }</span>
                </div>
              </a>
            )}
          </div>

          <div className="comment--footer">
            <button className="like-text" onClick={ this.onLike }>
              <div className={ `like-container ${ reply.is_liked ? 'like' : '' }` }>
                <div className="icon-block">
                  <img src={ reply.is_liked ? HeartMyIcon : HeartIcon } alt={ reply.is_liked ? "i like this" : "like icon" } />
                </div>
                <span>{ reply.likes_count }</span>
              </div>
            </button>

            <button className="like-text" onClick={ this.onReplyPress }>
              <div className="reply-container">
                <div className="icon-block">
                  <img src={ isActiveReply ? CloseIcon : ReplyIcon } alt="reply this comment" />
                </div>
                <span>{ isActiveReply ? 'cancel' : 'reply'  }</span>
              </div>
            </button>
          </div>
        { isActiveReply &&
          <WriteAComment
            onFocusComment={ onFocusComment }
            activeCommentPlaceholder
            removeAttachment={ removeAttachment }
            attachments={ attachments }
            addAttachment={ addAttachment }
            body={ body }
            onWriteComment={ onWriteComment }
            addComment={ this.addReply }
            placeholder='Write your reply here'
            buttonText='ADD REPLY'
            error={ error }
          />
        }
      </div>
    );
  }
}

export default RepliedComment;