import React from 'react';
import BackgroundImageSmall from '../../../imgs/u-hero-small.png';
import BackgroundImage from '../../../imgs/u-hero.png';
import GHero from '../../../imgs/g-hero.png';
import SearchRegBtn from '../../../imgs/search-reg-btn.svg';
import SearchFullBtn from '../../../imgs/search-full-btn.svg';
import medprotec from '../../../imgs/medprotec.png';

const DashboardSearch = ({ onFocus, onBlur, setInputRef, focused, onIconClick, onChange, searchValue }) => (
  <section className='sec-dashboard-search'>
    <div className="search-img limit-width">
      <picture>
        <source media="(max-width: 575px)" srcSet={ BackgroundImageSmall } />
        <source media="(min-width: 576px)" srcSet={ BackgroundImage } />
        <img src={ GHero } alt="doc" />
      </picture>
    </div>
    <div className="search-controls">
      <div className="navigation-sponsor" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
        <div className='sponsor-title'>
          <p style={{ margin: '10px 0' }}>{ 'Supported by: ' }</p>
        </div>
        <div className='support-logo'>
          <img src={ medprotec } alt="support logo" style={{ maxWidth: '150px' }} />
        </div>
      </div>
      <div className="container">
        <div className="search-container">
          <div className="search-field">
            <input
              ref={ ref => setInputRef(ref) }
              type="text"
              id='search_catg'
              placeholder='Consultation Search'
              onFocus={ onFocus }
              onBlur={ onBlur }
              onChange={ onChange }
              value={ searchValue }
            />
            <button
              className="search-btn"
              onClick={ onIconClick }
            >
              <img src={ searchValue ? SearchFullBtn : SearchRegBtn } alt="Search now" />
            </button>
          </div>
          <p className="search-hint-total">Type a category or a sub-category. E.g. ENT, Hypertension, Psoriasis etc</p>
        </div>
      </div>
    </div>
  </section>
);

export default DashboardSearch;