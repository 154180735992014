import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { USER_TYPES, GP_TYPES, DATE_CONSTANTS, ROUTES, IMAGE_URL } from '../../../constants';
import moment from 'moment';
import { getCommentCreatedDate } from '../../../services/Helpers';
import DeletedIcon from '../../../imgs/deleted-icon.svg';
// import EditIcon from '../../../imgs/edit-icon.svg';
import AlertIcon from '../../../imgs/alert-icon.svg';
import ArrowDown from '../../../imgs/arrow-down-icon.svg';
import ThreeDotsIcon from '../../../imgs/3dots-icon.svg';
import ImageIcon from '../../../imgs/image-icon.svg';
import PDFIcon from '../../../imgs/pdf-icon.svg';

class Report extends Component {
  constructor() {
    super();

    this.state = {
      showFullReport: false,
      isDropdownOpened: false
    };
  }

  onShowFull = () => {
    this.setState({ showFullReport: !this.state.showFullReport });
  }

  openDropdownMenu = () => {
    this.setState({ isDropdownOpened: !this.state.isDropdownOpened });
  }

  openEditCommentReplyModal = () => {
    const { report: { reportable, reportable_type }, globalActions } = this.props;

    globalActions.toggleEditCommentReplyModal(true, reportable, reportable_type.toLowerCase());

    this.setState({ isDropdownOpened: false });
  }

  onDeleteCommentReply = () => {
    const { report: { reportable, reportable_type }, globalActions } = this.props;

    globalActions.toggleDeleteCommentReplyModal(true, reportable, reportable_type.toLowerCase());

    this.setState({ isDropdownOpened: false });
  }

  render() {
    const { showFullReport, isDropdownOpened } = this.state;
    const { report: { user, reportable, created_at, reportable_type, body } } = this.props;
    const consultation_id = `${ ROUTES.CONSULT_EDIT.replace('/:id', '') }/${ reportable.consultation_id }`;

    return (
      <div className={ `report-container ${ showFullReport ? 'active' : '' }` }>
        <div className="list-item" onClick={ this.onShowFull }>
        <div className="item-ava">
          { user && user.avatar_url ?
            <div className="user-avatar avatar">
              <img src={ `${ IMAGE_URL }${ user.avatar_url  }` } alt="User profile"/>
            </div> :
            <div className="no-avatar avatar">
              <span>{ user.first_name.charAt(0) }</span><span>{ user.last_name.charAt(0) }</span>
            </div>
          }
        </div>
        <div className="item-profile">
          <div className="item-profile--user">
            <p>{ `${ user.first_name } ${ user.last_name }` }</p>
            <span>{ `${ user.gp_type ? GP_TYPES[user.gp_type] : USER_TYPES[user.user_type] } at ${ user.county }` }</span>
          </div>
          <div className="item-profile--time">
            <p>{ moment(created_at).utcOffset(created_at).format(DATE_CONSTANTS.DATE_UI) }</p>
            <span>{ moment(created_at).utcOffset(created_at).format(DATE_CONSTANTS.TIME_FORMAT) }</span>
          </div>
        </div>
        { reportable.user ?
          <div className="item-activity">
            <div className="item-activity--icon">
              <img src={ AlertIcon } alt="icon" />
            </div>
            <div className="item-activity--text">
              <span>{ `Reported ${ reportable_type.toLowerCase() } on ` }<Link to={ consultation_id }>{ ReactHtmlParser(reportable.consultation_title) || '' }</Link></span>
            </div>
            <div className="panel-toggle">
              <img src={ ArrowDown } alt="arrow" />
            </div>
          </div> :
          <div className="item-activity">
            <div className="item-activity--icon">
              <img src={ DeletedIcon } alt="icon"/>
            </div>
            <div className="item-activity--text">
              <span>Reported comment on <Link to={ consultation_id }>{ ReactHtmlParser(reportable.consultation_title) || '' }</Link></span><span className="action">deleted</span>
            </div>
            <div className="panel-toggle">
              <img src={ ArrowDown } alt="arrow"/>
            </div>
          </div>
        }
        {/* edited */}
        {/* <div className="item-activity">
          <div className="item-activity--icon">
            <img src={ EditIcon } alt="icon"/>
          </div>
          <div className="item-activity--text">
            <span>Reported comment on <a href="#">Chicken Pox</a></span><span className="action">edited</span>
          </div>
          <div className="panel-toggle">
            <img src={ ArrowDown } alt="arrow"/>
          </div>
        </div> */}
        {/* edited */}

        </div>
        { showFullReport &&
          <div className="complain-item">
            <div className="complain-text">
              <p>{ body }</p>
            </div>
            <div className="comment-card">
              <div className="card-container">
                <div className="comment--header">
                  <div className="header-ava">
                    { reportable && reportable.user && reportable.user.avatar_url ?
                      <div className="user-avatar avatar">
                        <img src={ `${ IMAGE_URL }${ reportable.user.avatar_url  }` } alt="User profile"/>
                      </div> :
                      <div className="no-avatar avatar">
                        <span>{ reportable.user ? reportable.user.first_name.charAt(0) : '' }</span><span>{ reportable.user ? reportable.user.last_name.charAt(0) : '' }</span>
                      </div>
                    }
                  </div>
                  <div className="header-info">
                    <p>{ `${ reportable.user ? reportable.user.first_name : '' } ${ reportable.user ? reportable.user.last_name : '' }` }</p>
                    { reportable.user &&
                      <p>{ `${ reportable.user.gp_type ? GP_TYPES[reportable.user.gp_type] : USER_TYPES[reportable.user.user_type] } at ${ reportable.user.county }` }</p>
                    }
                    <p>{ getCommentCreatedDate(reportable.created_at) }</p>
                  </div>
                  { reportable.user &&
                    <div className={ `header-controls ${ isDropdownOpened ? 'open' : '' }` }>
                      <button className="like-text" onClick={ this.openDropdownMenu }>
                        <img src={ ThreeDotsIcon } alt="menu"/>
                      </button>
                      <ul className='comment-dropdown-menu'>
                        <li onClick={ this.openEditCommentReplyModal }>Edit</li>
                        <li onClick={ this.onDeleteCommentReply }>Delete</li>
                      </ul>
                    </div>
                  }
                </div>
                <div className="comment--body">
                  <div className="text-output">
                    <p style={{ margin: 0 }}>{ reportable.body }</p>
                  </div>
                </div>
                <div className="comment--attachments">
                  { reportable.attachments && reportable.attachments.map((attachment, index) => (
                    <a key={ index } href={ `${ IMAGE_URL }${ attachment.attachment_url }` }>
                      <div className="attachment-single">
                        <img src={ attachment.attachment_url.match(/^.+\.(([pP][dD][fF]))$/) ? PDFIcon : ImageIcon } alt="file icon"/>
                        <span>{ attachment.id }</span>
                      </div>
                    </a>
                  )) }
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default Report;