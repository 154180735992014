import {PAYMENT_ACTIONS, INITIAL_STATE} from '../constants';

const paymentReducer = (state = INITIAL_STATE.PAYMENT_STATE, action) => {
  const {type, payload} = action || {};
  const {subscriptions} = payload || {};

  switch(type) {
    case PAYMENT_ACTIONS.CREATE_PAYMENT_SUCCESS: {
      return {
        ...state,
        subscribed: true,
      };
    }
    case PAYMENT_ACTIONS.GET_SUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        subscriptions,
      };
    }
    default:
      return state;
  };
}

export default paymentReducer;