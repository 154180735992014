import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router'
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import sagas from '../sagas';
import rootReducer from '../reducer';
import { appHistory } from '../services/HistoryConfig';

const sagaMiddleware = createSagaMiddleware();
let middleware = [sagaMiddleware];
const router = routerMiddleware(appHistory);

if(process.env.NODE_ENV !== 'production') {
  const logger = createLogger({
    level: 'info',
    collapsed: true,
  });

  middleware.push(logger);
}

middleware.push(router);

const createStoreWithMiddleware = applyMiddleware(...middleware)(createStore);

export function configure(initialState) {
  let store = createStoreWithMiddleware(rootReducer(appHistory), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(), initialState);

  sagaMiddleware.run(sagas, store);

  return store;
}

export default configure();