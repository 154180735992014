import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { globalActions, categoriesActions, paymentActions } from '../../actions';
import { Dashboard } from '../../components';

const mapStateToProps = state => {
  return {
    globalState: state.globalState,
    categories: state.categoriesState.categories,
    user: state.authState.user,
    subscriptions: state.paymentState.subscriptions,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    globalActions: bindActionCreators(globalActions, dispatch),
    categoriesActions: bindActionCreators(categoriesActions, dispatch),
    getSubscriptions: bindActionCreators(paymentActions.getSubscriptions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);