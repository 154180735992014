import React, { Component} from 'react';
import shortid from 'shortid';
import classNames from 'classnames';

  // name,
  // value,
  // error,
  // label,
  // onChange,
  // type,
  // classes = { wrapper: 'p-form-item', label: '', input: '', error: 'error-text' }

class ImageField extends Component {

  id = shortid();
  state = { forceDelete: false };

  onChange = (e) => {
    const { name } = this.props;
    const selectedFile = e.target.files[0];
    this.forceDelete(() => {
      this.props.onChange({ name, value: selectedFile })
    }, false);
  }

  imageUrl = () => {
    const { value } = this.props;

    if (value instanceof File) {
      return URL.createObjectURL(value);
    }

    if (typeof value === 'string') {
      return value;
    }
  }

  onDelete = (e) => {
    e.preventDefault();
    const { name } = this.props;
    this.forceDelete(() => {
      this.props.onChange({ name, value: '_delete' })
    }, true);
  }

  forceDelete(callback, action = true) {
    this.setState({
      forceDelete: action
    }, callback);
  }

  render() {
    const { name, label, initials, value } = this.props;
    const { forceDelete } = this.state;
    const id = `${name}-${this.id}`
    const [firstName, lastName] = (initials || '').split(' ');
    const imageUrl = this.imageUrl();
    const isValueEmpty = !value || forceDelete;
    return (
      <div className="profile-avatar">
        <div className="avatar-row">
          <label htmlFor={id} className="photo-block" style={{ cursor: 'pointer' }}>
            <div className="ava-container">
              {(!imageUrl || forceDelete) && <><span>{firstName}</span><span>{lastName}</span></>}
              {!!imageUrl && !forceDelete && <img src={imageUrl} alt='User profile' />}
            </div>
          </label>
          <div className="controls-block">
            <p>{label}</p>
            <div className="controls-wrapper">
              <label htmlFor={id} className="btn outlined small">change</label>
              <button
                onClick={this.onDelete}
                className={classNames("btn outlined small", { disabled: isValueEmpty })}
                disabled={isValueEmpty}
              >
                delete
              </button>
            </div>
          </div>
        </div>
        <input
          id={id}
          type="file"
          onChange={this.onChange}
          style={{ visibility: "hidden" }}
          accept="image/x-png,image/gif,image/jpeg,image/jpg"
        />
      </div>
    );
  }
}

export { ImageField };
