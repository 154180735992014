import { ADDITOONAL_DATA_ACTIONS } from '../constants';

export const getAdditionalData = (relation, id) => {
  return {
    type: ADDITOONAL_DATA_ACTIONS.GET_ADDITIONAL_DATA,
    payload: { relation, id }
  };
}

export const getAdditionalDataSuccess = (relation, values) => {
  return {
    type: ADDITOONAL_DATA_ACTIONS.GET_ADDITIONAL_DATA_SUCCESS,
    payload: { relation, values }
  };
}

export const commitProgress = (relation, id, progress = true) => {
  return {
    payload: { relation, id, progress },
    type: ADDITOONAL_DATA_ACTIONS.COMMIT_PROGRESS
  };
}
