import keymirror from 'keymirror';

export default keymirror({
  TOGGLE_MENU: null,
  TOGGLE_FOOTER: null,
  CLEAR_LOCAL_STORAGE: null,
  TOGGLE_ADD_CATEGORY_MODAL: null,
  TOGGLE_DELETE_CATEGORY_MODAL: null,
  TOGGLE_EDIT_CATEGORY_MODAL: null,
  TOGGLE_REPORT_MODAL: null,
  TOGGLE_EDIT_COMMENT_REPLY_MODAL: null,
  TOGGLE_DELETE_COMMENT_REPLY_MODAL: null,
  TOGGLE_DELETE_CONSULTATION_MODAL: null
});