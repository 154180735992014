import React from 'react';
import SmallResult from './SmallResult';
import LargeResult from './LargeResult';

const SearchResultCategories = ({
  letters,
  categories,
  onCategoryClick,
  selectedCategory,
  smallCategories,
  user,
  onDeleteCategory,
  onEditCategory,
  onDeleteConsultation
}) => (
  <section className="sec-search-content">
    <div className="container">
      <SmallResult
        categories={ smallCategories }
        user={ user }
        onDeleteCategory={ onDeleteCategory }
        onEditCategory={ onEditCategory }
        onDeleteConsultation={ onDeleteConsultation }
      />
      <LargeResult
        letters={ letters }
        categories={ categories }
        onCategoryClick={ onCategoryClick }
        selectedCategory={ selectedCategory }
        user={ user }
        onDeleteCategory={ onDeleteCategory }
        onEditCategory={ onEditCategory }
        onDeleteConsultation={ onDeleteConsultation }
      />
    </div>
  </section>
);

export default SearchResultCategories;