import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { globalActions, authActions } from '../../actions';
import { Header } from '../../components';

const mapStateToProps = state => {
  return {
    globalState: state.globalState,
    authState: state.authState,
    category: state.categoriesState.category,
    currentUserState: state.currentUserState
  };
}

const mapDispatchToProps = dispatch => {
  return {
    globalActions: bindActionCreators(globalActions, dispatch),
    authActions: bindActionCreators(authActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);