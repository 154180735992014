import { takeEvery, put, call } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { NUGGETS_ACTIONS, ROUTES } from '../constants';
import { nuggestActions, authActions } from '../actions';
import { processRequest } from '../services/Api';

function* handleGetNuggest(action) {
  try {
    const { payload } = action || {};
    const { meta } = payload || {};
    const { page, per_page } = meta;
    const getNuggetsSuccess = page === 1 ? nuggestActions.getNuggetsSuccess : nuggestActions.getNuggetsNextPageSuccess;
    const { data } = yield call(processRequest, `/nuggets?page=${page}&per_page=${per_page}`);

    if (data.nuggets) {
      yield put(getNuggetsSuccess(data.nuggets, data.total_pages));
    } else {
      yield put(nuggestActions.getNuggetsError(data));
    }
  } catch (e) {
    const { response } = e || {};
    const { data, status } = response || {};
    const { error_messages } = data || {};

    if(status === 400) {
      const keys = Object.keys(error_messages);
      const errorMessage = error_messages[keys[0]];
      const message = error_messages && `${ keys[0] } ${ errorMessage }`;

      yield put(nuggestActions.getNuggetsError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess());
      yield put(replace(ROUTES.HOME));
    } else {
      yield put(nuggestActions.getNuggetsError('Internal server error.'));
    }
  }
}

export function* watchNuggestSagas() {
  yield takeEvery(NUGGETS_ACTIONS.GET_NUGGETS, handleGetNuggest);
};
