const cachedData = {};

function cacheData(relation, id, value) {
  cachedData[`${relation}${id}`] = value;
}

function fetchCachedData(relation, id) {
  return cachedData[`${relation}${id}`];
}

export function fetchAdditionalData(data, relation, id) {
  const values = data[relation];

  const cachedVal = fetchCachedData(relation, id);

  if (cachedVal) {
    return cachedVal;
  }

  const value = (values || []).find(value => value.id === id);

  if (!value) {
    return null;
  }

  cacheData(relation, id, value);

  return value;
}

export function isRequestInProgress(data, relation, id) {
  if (!data.progress) {
    return false;
  }

  return !!data.progress[`${relation}#${id}`];
}
