import { takeEvery, put, call } from 'redux-saga/effects';
import { REPORTS_ACTIONS } from '../constants';
import { reportsActions, authActions } from '../actions';
import { processRequest } from '../services/Api';

function* handleGetReports() {
  try {
    const { data } = yield call(processRequest, `/reports`, 'GET');

    if(data.reports) {
      yield put(reportsActions.getReportsSuccess(data.reports));
    } else if(data.error_message) {
      yield put(reportsActions.getReportsError(data.error_message));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status } = response || {};
    const { error_messages } = data || {};

    const keys = Object.keys(error_messages);
    const errorMessage = error_messages[keys[0]];
    const message = error_messages && `${ keys[0] } ${ errorMessage }`;

    if(status === 400) {
      yield put(reportsActions.getReportsError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess());
    } else {
      yield put(reportsActions.getReportsError('Internal server error.'));
    }
  }
}

function* handleGetReviewedReports() {
  try {
    const { data } = yield call(processRequest, `/reviewed_list`, 'GET');

    if(data.reports) {
      yield put(reportsActions.getReviewedReportsSuccess(data.reports));
    } else if(data.error_message) {
      yield put(reportsActions.getReviewedReportsError(data.error_message));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status } = response || {};
    const { error_messages } = data || {};

    const keys = Object.keys(error_messages);
    const errorMessage = error_messages[keys[0]];
    const message = error_messages && `${ keys[0] } ${ errorMessage }`;

    if(status === 400) {
      yield put(reportsActions.getReviewedReportsError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess());
    } else {
      yield put(reportsActions.getReviewedReportsError('Internal server error.'));
    }
  }
}

function* handleRevieweReport(action) {
  try {
    const { report_id } = action.payload || {};
    const { data } = yield call(processRequest, `/reviewed?id=${ report_id }`, 'GET');

    if(data.reports) {
      yield put(reportsActions.getReviewedReports());
      yield put(reportsActions.reviewReportSuccess());
    } else if(data.error_message) {
      yield put(reportsActions.reviewReportError(data.error_message));
    }
  } catch(e) {
    const { response} = e || {};
    const { data, status } = response || {};
    const { error_messages } = data || {};

    const keys = Object.keys(error_messages);
    const errorMessage = error_messages[keys[0]];
    const message = error_messages && `${ keys[0] } ${ errorMessage }`;

    if(status === 400) {
      yield put(reportsActions.reviewReportError(message));
    } else if(status === 401) {
      yield put(authActions.logoutSuccess());
    } else {
      yield put(reportsActions.reviewReportError('Internal server error.'));
    }
  }
}

export function* watchReportsSagas() {
  yield takeEvery(REPORTS_ACTIONS.GET_REPORTS, handleGetReports);
  yield takeEvery(REPORTS_ACTIONS.GET_REVIEWED_REPORTS, handleGetReviewedReports);
  yield takeEvery(REPORTS_ACTIONS.REVIEW_REPORT, handleRevieweReport);
};
