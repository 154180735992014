import { AUTH_ACTIONS, INITIAL_STATE, LOCAL_STORAGE } from '../constants';

export default function authReducer(state = INITIAL_STATE.AUTH_STATE, action) {
  const { errorMessage, successMessage, user } = action.payload || {};

  switch(action.type) {
    case AUTH_ACTIONS.LOGIN_SUCCESS: {
      localStorage.setItem(LOCAL_STORAGE.USER, JSON.stringify(user));
      localStorage.setItem(LOCAL_STORAGE.AUTH_TOKEN, user.auth_token);

      return {
        ...state,
        user,
        errorMessage: null
      };
    }
    case AUTH_ACTIONS.SIGN_UP_SUCCESS:
    case AUTH_ACTIONS.UPDATE_USER_SUCCESS: {
      localStorage.setItem(LOCAL_STORAGE.USER, JSON.stringify(user));
      localStorage.setItem(LOCAL_STORAGE.AUTH_TOKEN, user.auth_token);

      return {
        ...state,
        user,
        errorMessage: null
      };
    }
    case AUTH_ACTIONS.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        successMessage
      };
    }
    case AUTH_ACTIONS.LOGIN_ERROR:
    case AUTH_ACTIONS.SIGN_UP_ERROR: {
      localStorage.removeItem(LOCAL_STORAGE.USER);
      localStorage.removeItem(LOCAL_STORAGE.AUTH_TOKEN);

      return {
        ...state,
        errorMessage,
        successMessage
      }
    }
    case AUTH_ACTIONS.ENTER_USERNAME_AND_PASSWORD_ERROR:
    case AUTH_ACTIONS.FORGOT_PASSWORD_ERROR:
    case AUTH_ACTIONS.SAVE_NEW_PASSWORD_ERROR:
    case AUTH_ACTIONS.CHANGE_PASSWORD_ERROR:
    case AUTH_ACTIONS.REQUEST_USER_DATA_ERROR: {
      return {
        ...state,
        errorMessage,
        successMessage
      }
    }
    case AUTH_ACTIONS.UPDATE_USER_ERROR: {
      return {
        ...state,
        errorMessage
      };
    }
    case AUTH_ACTIONS.LOGOUT_SUCCESS: {
      localStorage.removeItem(LOCAL_STORAGE.USER);
      localStorage.removeItem(LOCAL_STORAGE.AUTH_TOKEN);

      return {
        ...state,
        user: null,
        errorMessage: null
      }
    }
    case AUTH_ACTIONS.CHANGE_PASSWORD_SUCCESS: {
      localStorage.setItem(LOCAL_STORAGE.USER, JSON.stringify(user));
      localStorage.setItem(LOCAL_STORAGE.AUTH_TOKEN, user.auth_token);

      return {
        ...state,
        successMessage
      };
    }
    case AUTH_ACTIONS.GET_USER_DATA: {
      const user = localStorage.getItem(LOCAL_STORAGE.USER);
      const parsedUser = user ? JSON.parse(user) : null;

      return {
        ...state,
        user: parsedUser,
        errorMessage: null
      };
    }
    case AUTH_ACTIONS.REQUEST_USER_DATA_SUCCESS: {
      localStorage.setItem(LOCAL_STORAGE.USER, JSON.stringify(user));

      return {
        ...state,
        user
      };
    }
    default:
      return state;
  }
};