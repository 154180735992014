import React from 'react';
import { IMAGE_URL } from '../../../constants';
import PDFIcon from '../../../imgs/pdf-icon.svg';
import Disclaimer from './Disclaimer';
import ConsultationHeader from './ConsultationHeader';
import ConsultationInfo from './ConsultationInfo';

const ConsultationCard = ({
  consultation,
  hideDisclaimer,
  onHideDisclaimer,
  goBack,
  user,
  onSponsorClick,
  handleCopy,
}) => (
  <section className="sec-consult-main">
    <div className="container">
      <ConsultationHeader
        consultation={ consultation }
        goBack={ goBack }
        user={ user }
        onSponsorClick={ onSponsorClick }
        handleCopy={ handleCopy }
      />
      <div className="consult-container-editor">
        <div className="container-small">
          { !hideDisclaimer &&
            <Disclaimer
              disclaimer={ consultation.disclaimer }
              onHideDisclaimer={ onHideDisclaimer }
            />
          }
          <ConsultationInfo
            title={ consultation.title }
            consultation={ consultation }
            user={ user }
            onSponsorClick={ onSponsorClick }
            attachmentName=''
          />
          <ConsultationInfo
            name={ 'History' }
            description={ consultation.history }
            className='history'
            attachments={ consultation.attachments }
            attachmentName='history'
          />
          <ConsultationInfo
            name={ 'Exam' }
            description={ consultation.exam }
            className='exam'
            attachments={ consultation.attachments }
            attachmentName='exam'
          />
          <ConsultationInfo
            name={ 'impression' }
            description={ consultation.impresion }
            className='impression'
            attachments={ consultation.attachments }
            attachmentName='impression'
          />
          <ConsultationInfo
            name={ 'plan' }
            description={ consultation.plan }
            className='plan'
            attachments={ consultation.attachments }
            attachmentName='plan'
          />
          <ConsultationInfo
            name={ 'additional info' }
            description={ consultation.aditional_info }
            className='additional'
            attachments={ consultation.attachments }
            attachmentName='aditional_info'
          />
          {consultation.pdf_url ?
            <div style={{marginBottom: '40px'}}>
              <div className={`editor- viewer-w`}>
                <div className="editor-w--label">{ 'Pharmacology Nuggets' }</div>
                <div className="viewer--viewer">
                  <a href={ `${ IMAGE_URL }${ consultation.pdf_url }` } target="_blank" rel="noopener noreferrer">
                    <div className="attachment-single">
                      <img src={ PDFIcon } alt="file icon" />
                      <span>{ consultation.pdf_file_name }</span>
                    </div>
                  </a>
                </div>
              </div>
            </div> : null
          }
        </div>
      </div>
    </div>
  </section>
);

export default ConsultationCard;