import React from 'react';
import { PASSWORD } from '../../../../constants';
import { PlainInput } from '../../Inputs/Input';
import { isEmpty } from 'lodash';


export function ChangePasswordForm({ onChange, onSubmit, inputProps, formData, currentUser, errors, message, passwordError }) {

  return <>
   <div className="tab tab-account">
     <form name="password" onSubmit={onSubmit}>
       { passwordError &&
          <span className='main-auth-error'>{ passwordError }</span>
        }
        <PlainInput
          {...inputProps('old_password')}
          label="old password"
          type="password"
        />
        <PlainInput
          {...inputProps('password')}
          label="new password"
          type="password"
        />
        <PlainInput
          {...inputProps('password_confirmation')}
          label="confirm new password"
          type="password"
        />
        { message && <p className='profile-updated-success-message'>{ message }</p> }
       <button className='btn large filled submit'>change</button>
     </form>
   </div>
  </>
};

ChangePasswordForm.fields = ['old_password', 'password', 'password_confirmation'];
ChangePasswordForm.validate = (formData) => {
  const errors = {};
  const { old_password, password, password_confirmation } = formData;

  if(!old_password) {
    errors.old_password = ['This field must be specified'];
  }
  if(!password) {
    errors.password = ['Please enter password'];
  }else if(!PASSWORD.test(password)) {
    errors.password = ['Password must be more than 7 characters & contain at least one number & one capital letter.'];
  }
  if(!password_confirmation) {
    errors.password_confirmation = ['Please confirm password'];
  }
  if(password && password_confirmation && password !== password_confirmation) {
    errors.password = [' '];
    errors.password_confirmation = ['New Password and Confirm New Password must be the same'];
  }

  if (isEmpty(errors)) {
    return false;
  }

  return errors;
}
