import React from 'react';
import Modal from 'react-modal';
import { MenuCross, AuthenticationButton } from '../../';

const ReportModal = ({
  isOpen,
  onClose,
  report,
  onReportChange,
  title,
  onClick,
  buttonTitle,
  type,
  isSent,
  reportedSuccess,
  error
}) => (
  <Modal
    isOpen={ isOpen }
    onRequestClose={ onClose }
    contentLabel="ReportModal"
    className='modal'
    appElement={ document.getElementById('app') }
  >
    <div className='modal-container'>
      { error &&
        <span className='main-auth-error' style={{ marginLeft: '0', textAlign: 'center' }}>{ error }</span>
      }
      <span className='title'>{ title }</span>
      <button
        className="modal-close-btn"
        tabIndex="1"
        onClick={ onClose }
      >
        { MenuCross }
      </button>
      <div className="rightnow--text">
        { isSent ?
          <span className='reported-success-text'>{ reportedSuccess }</span> :
          <textarea
            cols="70"
            rows="3"
            onChange={ onReportChange }
            value={ report }
            placeholder={ `Please tell us why you are reporting this ${ type === 'comment' ? 'comment' : 'reply' }?` }
          />
        }
      </div>
      <AuthenticationButton
        title={ buttonTitle }
        onClick={ onClick }
      />
    </div>
  </Modal>
);

export default ReportModal;