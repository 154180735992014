import { REPORTS_ACTIONS } from '../constants';

export const getReports = () => {
  return {
    type: REPORTS_ACTIONS.GET_REPORTS
  };
}

export const getReportsSuccess = reports => {
  return {
    type: REPORTS_ACTIONS.GET_REPORTS_SUCCESS,
    payload: { reports }
  };
}

export const getReportsError = error => {
  return {
    type: REPORTS_ACTIONS.GET_REPORTS_ERROR,
    payload: { error }
  };
}

export const getReviewedReports = () => {
  return {
    type: REPORTS_ACTIONS.GET_REVIEWED_REPORTS
  };
}

export const getReviewedReportsSuccess = revviewedReports => {
  return {
    type: REPORTS_ACTIONS.GET_REVIEWED_REPORTS_SUCCESS,
    payload: { revviewedReports }
  };
}

export const getReviewedReportsError = error => {
  return {
    type: REPORTS_ACTIONS.GET_REVIEWED_REPORTS_ERROR,
    payload: { error }
  };
}

export const reviewReport = report_id => {
  return {
    type: REPORTS_ACTIONS.REVIEW_REPORT,
    payload: { report_id }
  };
}

export const reviewReportSuccess = () => {
  return {
    type: REPORTS_ACTIONS.REVIEW_REPORT_SUCCESS
  };
}

export const reviewReportError = error => {
  return {
    type: REPORTS_ACTIONS.REVIEW_REPORT_ERROR,
    payload: { error }
  };
}