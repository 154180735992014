import keymirror from 'keymirror';

export default keymirror({
  START_FREE_TRIAL: null,
  START_FREE_TRIAL_SUCCESS: null,
  START_FREE_TRIAL_ERROR: null,
  CREATE_PAYMENT: null,
  CREATE_PAYMENT_SUCCESS: null,
  CREATE_PAYMENT_ERROR: null,
  GET_SUBSCRIPTIONS: null,
  GET_SUBSCRIPTIONS_SUCCESS: null,
  GET_SUBSCRIPTIONS_ERROR: null
});