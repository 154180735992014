import React from 'react';
import { Input, AuthenticationButton } from '../../';

const HeaderSignInForm = ({ onSubmit, errors, handleEmail, email, handlePassword, password }) => (
  <form onSubmit={ onSubmit }>
    <Input
      type='email'
      placeholder='Email Address'
      styleClass={ errors && errors.email ? 'error' : '' }
      error={ errors && errors.email && errors.email }
      onInputChange={ handleEmail }
      value={ email }
    />
    <Input
      type='password'
      placeholder='Password'
      styleClass={ `password ${ errors && errors.password ? 'error' : '' }` }
      error={ errors && errors.password && errors.password }
      onInputChange={ handlePassword }
      value={ password }
      hideIcon
    />
    <AuthenticationButton title='sign in' />
  </form>
);

export default HeaderSignInForm;