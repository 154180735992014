import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { App } from '../../components';
import { globalActions, categoriesActions, commentsActions, consultationsActions } from '../../actions';

const mapStateToProps = state => {
  return {
    globalState: state.globalState,
    categoriesState: state.categoriesState,
    reportedSuccess: state.commentsState.reportedSuccess
  };
}

const mapDispatchToProps = dispatch => {
  return {
    globalActions: bindActionCreators(globalActions, dispatch),
    categoriesActions: bindActionCreators(categoriesActions, dispatch),
    commentsActions: bindActionCreators(commentsActions, dispatch),
    consultationsActions: bindActionCreators(consultationsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);