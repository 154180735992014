import React from 'react';
import moment from 'moment';
import { DATE_CONSTANTS } from '../../../constants';
import {capitalize} from '../../../services';

const RED = '#E53935';
const YELLOW = '#FBC02D';
const GREEN = '#43A047';
const BLUE = '#0085c5';
const getColor = type => {
  let color;
  switch(type){
    case 'pending':
      color = YELLOW;
      break;
    case 'success':
      color = GREEN;
      break;
    case 'failed':
      color = RED;
      break;
    case 'refunded':
      color = BLUE;
      break;
  }
  return color;
};

const Subscription = ({subscription, user}) => {
  return (
    <div className="list-item" style={{
      display: 'flex',
      flex: '1',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }}>
      <div className="item-profile--user" style={{width: '25%'}}>
        <p>{capitalize(subscription.subscription_type)}</p>
      </div>
      <div className="item-profile--user" style={{width: '25%'}}>
        <p>{moment(subscription.created_at).format(DATE_CONSTANTS.PAYMENTS)}</p>
      </div>
      <div className="item-profile--user" style={{width: '25%'}}>
        <p>{moment(user.subscription_end_time).format(DATE_CONSTANTS.PAYMENTS)}</p>
      </div>
      <div className="item-profile--user" style={{width: '25%'}}>
        <p>{Number(subscription.amount) / 100 || 'Free'}</p>
      </div>
      <div className="item-profile--user" style={{width: '25%'}}>
        <p style={{color: getColor(subscription.status)}}>{capitalize(subscription.status)}</p>
      </div>
    </div>
  );
}

export default Subscription;