import React, { Component } from 'react';
import UpdateListItem from '../../ui/Updates';
import { debounce } from 'lodash';
// import { fetchAdditionalData } from '../../../services';

class Updates extends Component {
  debouncedLoadMore = debounce(this.onLoadMore, 300);
  // processedFeeds = [];
  // feedableTypeAdditionalDataProcessor = {
  //   User: null,
  //   Comment: this._processAdditionalDataComment,
  //   Like: this._processAdditionalDataLike,
  //   Reply: this._processAdditionalDataReply
  // }

  componentDidMount() {
    const {getUpdatedConsultations, meta} = this.props;
    const newMeta = {
      ...meta,
      page: 1,
    };
    getUpdatedConsultations(newMeta);
  }

  onLoadMore() {
    const {getUpdatedConsultations, meta} = this.props;
    const newMeta = {
      ...meta,
      page: meta.page + 1,
    };
    getUpdatedConsultations(newMeta);
  }

  // fetchAdditionalData = (relation, id) => {
  //   const { additionalData } = this.props;

  //   return fetchAdditionalData(additionalData, relation, id);
  // }

  // _loadAdditionalData = () => {
  //   const { values } = this.props;
  //   const { feedableTypeAdditionalDataProcessor, processedFeeds } = this;
  //   values.forEach(value => {
  //     const processFun = feedableTypeAdditionalDataProcessor[value.feedable_type]

  //     if (processedFeeds.includes(value.id) || !processFun) {
  //       return;
  //     }

  //     processFun.bind(this)(value);
  //   });
  // }

  // _processAdditionalDataLike(value) {
  //   const { additionalDataActions } = this.props;
  //   const feedableValue = value.feedable;

  //   if (!feedableValue || !feedableValue.likeable || !feedableValue.likeable.consultation_id) {
  //     return;
  //   }

  //   additionalDataActions.getAdditionalData('consultations', feedableValue.likeable.consultation_id);
  //   this.processedFeeds.push(value.id);
  // }

  // _processAdditionalDataComment(value) {
  //   const { additionalDataActions } = this.props;
  //   const feedableValue = value.feedable;

  //   if (!feedableValue || !feedableValue.consultation_id) {
  //     return;
  //   }

  //   additionalDataActions.getAdditionalData('consultations', feedableValue.consultation_id);
  //   this.processedFeeds.push(value.id);
  // }

  // _processAdditionalDataReply(value) {
  //   const { additionalDataActions } = this.props;
  //   const feedableValue = value.feedable;

  //   if (!feedableValue || !feedableValue.comment_id) {
  //     return;
  //   }

  //   additionalDataActions.getAdditionalData('comments', feedableValue.comment_id);
  //   this.processedFeeds.push(value.id);
  // }

  renderUpdatesList = () => {
    const { values, user } = this.props;
    return values.map(value => (
      <UpdateListItem key={value.id} value={value} user={user} />
    ));
  }

  render() {
    return (
      <section className="sec-activity">
        <div className="container">
          <div className="activity-title">
            <p>Updates</p>
          </div>
          <div className="activity-list">
            <div className="list-container">
              {this.renderUpdatesList()}
            </div>

            {!this.props.isAllLoaded && <div className="list-footer">
              <button onClick={this.debouncedLoadMore.bind(this)} className="btn large filled">SHOW MORE</button>
            </div>}
          </div>
        </div>
      </section>
    );
  }
}

export default Updates;
