import { GLOBAL_ACTIONS, INITIAL_STATE, LOCAL_STORAGE } from '../constants';

export default function globalReducer(state = INITIAL_STATE.GLOBAL_STATE, action) {
  const {
    isOpen,
    showFooter,
    isOpenCategoryModal,
    isOpenDeleteCategoryModal,
    isOpenEditCategoryModal,
    isOpenReportModal,
    type,
    reportable,
    category,
    isOpenEditCommentReplyModal,
    commentReply,
    isOpenDeleteCommentReplyModal,
    isOpenDeleteConsultationModal,
    consultation
  } = action.payload || {};

  switch(action.type) {
    case GLOBAL_ACTIONS.TOGGLE_MENU: {
      return {
        ...state,
        isOpen
      };
    }
    case GLOBAL_ACTIONS.TOGGLE_FOOTER: {
      return {
        ...state,
        showFooter
      };
    }
    case GLOBAL_ACTIONS.CLEAR_LOCAL_STORAGE: {
      localStorage.removeItem(LOCAL_STORAGE.USER_ID);

      return state;
    }
    case GLOBAL_ACTIONS.TOGGLE_ADD_CATEGORY_MODAL: {
      return {
        ...state,
        isOpenCategoryModal
      };
    }
    case GLOBAL_ACTIONS.TOGGLE_DELETE_CATEGORY_MODAL: {
      return {
        ...state,
        isOpenDeleteCategoryModal,
        category
      };
    }
    case GLOBAL_ACTIONS.TOGGLE_EDIT_CATEGORY_MODAL: {
      return {
        ...state,
        isOpenEditCategoryModal,
        category
      };
    }
    case GLOBAL_ACTIONS.TOGGLE_REPORT_MODAL: {
      return {
        ...state,
        isOpenReportModal,
        type,
        reportable
      };
    }
    case GLOBAL_ACTIONS.TOGGLE_EDIT_COMMENT_REPLY_MODAL: {
      return {
        ...state,
        isOpenEditCommentReplyModal,
        commentReply,
        type
      };
    }
    case GLOBAL_ACTIONS.TOGGLE_DELETE_COMMENT_REPLY_MODAL: {
      return {
        ...state,
        isOpenDeleteCommentReplyModal,
        commentReply,
        type
      };
    }
    case GLOBAL_ACTIONS.TOGGLE_DELETE_CONSULTATION_MODAL: {
      return {
        ...state,
        isOpenDeleteConsultationModal,
        consultation
      };
    }
    default:
      return state;
  }
};