import React, { Component } from 'react';
import Switch from "react-switch";
import {IMAGE_URL} from '../../../constants';
import CloseIcon from '../../../imgs/close-icon.svg';
import IconClip from '../../../imgs/icon-clip.svg';
import PDFIcon from '../../../imgs/pdf-icon.svg';

class InputWithoutEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: !!props.value,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { value } = this.props;

    if(nextProps.value && value !== nextProps.value)
      this.setState({ checked: !!nextProps.value });
  }

  onToggleSwitch = () => {
    this.props.onChange('');
    if(this.state.checked) this.props.handleDeletePdf();
    this.setState({ checked: !this.state.checked, focused: false });
  }

  deletePDF = () => {
    this.props.onChange('');
    this.props.handleDeletePdf();
  }

  render() {
    const { checked } = this.state;
    const {
      title,
      value,
      name,
      onChange,
    } = this.props;

    return (
      <div style={{marginBottom: '40px'}}>  
        <div className={ `editor- editor-w` }>
          <div className="editor-w--toggle">
            <Switch
              checked={ checked }
              onChange={ this.onToggleSwitch }
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={ 30 }
              uncheckedIcon={ false }
              checkedIcon={ false }
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              height={ 20 }
              width={ 48 }
              className="react-switch"
            />
          </div>
          <div className="editor-w--label">{ title }</div>
          <div className="editor-w--editor">
          { value ?
            <div className="clip-block">
              <a className="like-text" href={typeof value === 'string' ? `${IMAGE_URL}/${value}` : value.pdf_url} target="_blank">
                <img src={ PDFIcon } alt="add file" />
              </a>
              <button className="like-text" onClick={this.deletePDF}>
                <img src={ CloseIcon } alt="delete" />
              </button>
            </div> :
            <div className="clip-block">
              <input
                id={name}
                type='file'
                style={{ display: 'none' }}
                className="like-text"
                accept=".pdf"
                onChange={ onChange }
              />
              <div>
                <label htmlFor={name}>
                  <img src={ IconClip } alt="add file" />pdf
                </label>
              </div>
            </div>
          }
          </div>
        </div>

      {/* { sectionAttachments &&
        sectionAttachments.length > 0 &&
          <div className="rightnow--attachments">
            { sectionAttachments.map((attachment, index) =>
              <div key={ index } className="attachment-single">
                <img src={ attachment.attachment_content_type === 'application/pdf' || (attachment.document_file_name && attachment.document_file_name.match('pdf')) ? PDFIcon : ImageIcon } alt="file icon" />
                <span>{ attachment.name || attachment.document_file_name }</span>
                <button className="like-text" onClick={ () => removeConsultationAttachment(index) }>
                  <img src={ CloseIcon } alt="delete" />
                </button>
              </div>
            )}
          </div>
        } */}
        {/* <div className="rightnow--buttons">
          { sectionAttachments &&
            sectionAttachments.length === 3 ?
            <div className="clip-block">
              <button className="like-text" disabled>
                <div>
                  <img src={ IconClip } alt="add file"/>
                  <span>3 maximum</span>
                </div>
              </button>
            </div> :
            <div className="clip-block">
              <input
                id={name}
                type='file'
                style={{ display: 'none' }}
                className="like-text"
                accept=".pdf"
                onChange={ addConsultationAttachment }
              />
              <div>
                <label htmlFor={name}>
                  <img src={ IconClip } alt="add file" />pdf
                </label>
              </div>
            </div>
          }
        </div> */}
      </div>
    )
  }
}

export default InputWithoutEditor;