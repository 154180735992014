import {SUGGESTION_ACTIONS} from '../constants';

export const sendSuggestion = (text, consultation_id) => ({
  type: SUGGESTION_ACTIONS.SEND_SUGGESTION,
  payload: {text, consultation_id},
});

export const sendSuggestionSuccess = suggestionSentSuccess => ({
  type: SUGGESTION_ACTIONS.SEND_SUGGESTION_SUCCESS,
  payload: {suggestionSentSuccess},
});

export const sendSuggestionError = error => ({
  type: SUGGESTION_ACTIONS.SEND_SUGGESTION_ERROR,
  payload: {error},
});

export const clearSuccessMessage = () => ({
  type: SUGGESTION_ACTIONS.CLEAR_SUCCESS_MESSAGE,
});