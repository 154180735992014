import React from 'react';
import { Input, AuthenticationButton } from '../../';
import { ROUTES } from '../../../constants';

const SignInForm = ({ onSubmit, errors, handleEmail, email, handlePassword, password, onShowPassword, showPassword }) => (
  <form onSubmit={ onSubmit }>
    <Input
      type='email'
      placeholder='Email Address'
      styleClass={ errors && errors.email ? 'error' : '' }
      error={ errors && errors.email && errors.email }
      onInputChange={ handleEmail }
      value={ email }
    />
    <Input
      type='password'
      placeholder='Password'
      styleClass={ `password ${ errors && errors.password ? 'error' : '' }` }
      error={ errors && errors.password && errors.password }
      forgotPasswordPath={ ROUTES.FORGOT_PASSWORD }
      onInputChange={ handlePassword }
      value={ password }
      onShowPassword={ onShowPassword }
      showPassword={ showPassword }
    />
    <AuthenticationButton title='sign in' />
  </form>
);

export default SignInForm;