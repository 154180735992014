import { takeEvery, put, call, select } from 'redux-saga/effects';
import { ADDITOONAL_DATA_ACTIONS } from '../constants';
import { additionalDataActions } from '../actions';
import { processRequest } from '../services/Api';
import { fetchAdditionalData, isRequestInProgress } from '../services';

const RELATION_REQUEST = {
  consultations: requestConsultation,
  comments: requestComments
};

const RELATION_ROOT = {
  consultations: 'consultation',
  comments: 'comment'
};

function* requestConsultation(id) {
  return yield call(processRequest, `/consultations/${id}`, 'GET');
}

function* requestComments(id) {
  return yield call(processRequest, `/comments/${id}`, 'GET');
}

function* handleGetAdditionalData({ action, payload }) {
  const { relation, id } = payload;
  try {
    const request = RELATION_REQUEST[relation];

    if (!request || !id) {
      console.error(`Cannot load ${relation}#${id}`);
      return;
    }

    const additionalData = yield select(state => state.additionalDataState);
    const cachedData = fetchAdditionalData(additionalData, id, relation);
    const isInProgress = isRequestInProgress(additionalData, id, relation);

    if (!!cachedData || isInProgress) {
      return;
    }

    yield put( additionalDataActions.commitProgress(relation, id));

    const { data } = yield call(request, id);

    const newData = data[RELATION_ROOT[relation]];

    if (!newData) {
      return;
    }

    yield put(additionalDataActions.getAdditionalDataSuccess(
      relation,
      [newData]
    ));

    yield put(additionalDataActions.commitProgress(relation, id, false));
  } catch (e) {
    yield put(additionalDataActions.commitProgress(relation, id, false));
    console.error(`Cannot load ${relation}#${id} ${e}`);
  }
}

export function* watchAdditionalDataSagas() {
  yield takeEvery(ADDITOONAL_DATA_ACTIONS.GET_ADDITIONAL_DATA, handleGetAdditionalData);
};

