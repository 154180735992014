import { INITIAL_STATE, ADDITOONAL_DATA_ACTIONS } from '../constants';

export default function additionalDataReducer(state = INITIAL_STATE.ADDITOONAL_DATA_STATE, action) {
  const { relation, values, id, progress } = action.payload || {};

  switch(action.type) {
    case ADDITOONAL_DATA_ACTIONS.GET_ADDITIONAL_DATA_SUCCESS:
      return {
        ...state,
        [relation]: [...state[relation], ...values]
      };
    case ADDITOONAL_DATA_ACTIONS.COMMIT_PROGRESS:
      return {
        ...state,
        progress: { ...state.progress, [`${relation}#${id}`]: progress }
      }
    default:
      return state;
  }
};
