import keymirror from 'keymirror';

export default keymirror({
  GET_REPORTS: null,
  GET_REPORTS_SUCCESS: null,
  GET_REPORTS_ERROR: null,
  GET_REVIEWED_REPORTS: null,
  GET_REVIEWED_REPORTS_SUCCESS: null,
  GET_REVIEWED_REPORTS_ERROR: null,
  REVIEW_REPORT: null,
  REVIEW_REPORT_SUCCESS: null,
  REVIEW_REPORT_ERROR: null
});