import React, { Component } from 'react'
import ReactGA from 'react-ga';
import {
  CreateEditCategoryModal,
  DeleteCategoryConfirmationModal,
  ReportModal,
  EditCommentReplyModal,
  DeleteCommentReplyModal,
  DeleteConsultationConfirmationModal
} from '../../';

let pathname = '';

class App extends Component {
  constructor() {
    super();

    this.state = {
      categoryTitle: '',
      report: '',
      reportModalTitle: '',
      comment: '',
      body: '',
      error: ''
    };
  }

  componentWillMount() {
    ReactGA.initialize('UA-141245531-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentWillReceiveProps(nextProps) {
    const { globalState: { category, commentReply } } = nextProps;
    if(window.location.pathname !== pathname) {
      pathname = window.location.pathname;

      ReactGA.pageview(pathname);
    }
    if(category && category.title !== this.state.categoryTitle) {
      this.setState({ categoryTitle: category.title });
    }
    if(commentReply && commentReply.body !== this.state.body) {
      this.setState({ body: commentReply.body });
    }
  }

  onCategoryTitleChange = event => {
    const categoryTitle = event.target.value;

    this.setState({ categoryTitle });
  }

  toggleAddCategoryModal = () => {
    this.setState({ categoryTitle: '' });
    this.props.globalActions.toggleAddCategoryModal(false);
  }

  onCategoryCreate = () => {
    const { categoryTitle } = this.state;

    if(!categoryTitle || categoryTitle === '') {
      this.setState({ error: 'Please enter a category title' });
    } else {
      this.props.categoriesActions.createCategory(this.state.categoryTitle);
      this.setState({ categoryTitle: '', error: '' });
    }
  }

  onCategoryEdit = () => {
    const { categoriesActions, globalState: { category } } = this.props;
    categoriesActions.editCategory(category.id, this.state.categoryTitle);

    this.setState({ categoryTitle: '' });
  }

  toggleEditCategoryModal = () => {
    this.setState({ categoryTitle: '' });
    this.props.globalActions.toggleEditCategoryModal(false);
  }

  toggleDeleteCategoryModal = () => {
    this.props.globalActions.toggleDeleteCategoryModal(false);
  }

  onCategoryDelete = () => {
    const { categoriesActions, globalState: { category } } = this.props;

    categoriesActions.deleteCategory(category.id);
  }

  onReportChange = event => {
    const report = event.target.value;

    this.setState({ report });
  }

  toggleReportModal = () => {
    const { globalActions, commentsActions } = this.props;

    commentsActions.clearSuccessMessage();
    globalActions.toggleReportModal(false);
  }

  sendReport = () => {
    const { globalState: { type, reportable }, commentsActions } = this.props;
    const { report } = this.state;

    if(!report || report === '') {
      this.setState({ error: `Please enter a report text` });
    } else {
      if(type === 'comment')
        commentsActions.reportForComment(reportable.id, report, reportable.consultation.id);
      else
        commentsActions.reportForReply(reportable.id, report);

      this.setState({ report: '', error: '' });
    }

  }

  onChangeBody = event => {
    const body = event.target.value;

    this.setState({ body });
  }

  onCloseEditCommentReplyModal = () => {
    const { globalActions } = this.props;

    globalActions.toggleEditCommentReplyModal(false);

    this.setState({ body: '' });
  }

  onSaveEdditedCommentReply = () => {
    const { commentsActions, globalActions, globalState: { type, commentReply } } = this.props;
    const { body } = this.state;

    if(!body || body === '') {
      this.setState({ error: `Please enter a ${ type === 'comment' ? 'comment.' : 'reply.' }` });
    } else {
      if(type === 'comment')
        commentsActions.editComment(commentReply, body)
      else
        commentsActions.editReply(commentReply, body);

      globalActions.toggleEditCommentReplyModal(false);
      this.setState({ body: '', error: '' });
    }
  }

  toggleDeleteCommentReplyModal = () => {
    const { globalActions } = this.props;

    globalActions.toggleDeleteCommentReplyModal(false);
  }

  onDeleteComment = () => {
    const { globalActions, commentsActions, globalState: { commentReply } } = this.props;

    commentsActions.deleteComment(commentReply);
    globalActions.toggleDeleteCommentReplyModal(false);
  }

  onDeleteReply = () => {
    const { globalActions, commentsActions, globalState: { commentReply } } = this.props;

    commentsActions.deleteReply(commentReply);
    globalActions.toggleDeleteCommentReplyModal(false);
  }

  toggleDeleteConsultationModal = () => {
    const { globalActions } = this.props;

    globalActions.toggleDeleteConsultationModal(false);
  }

  onConsultationDelete = () => {
    const { globalActions, consultationsActions, globalState: { consultation } } = this.props;

    consultationsActions.deleteConsultation(consultation);
    globalActions.toggleDeleteConsultationModal(false);
  }

  render() {
    const { globalState, children, globalState: { category }, reportedSuccess } = this.props;
    const { categoryTitle, report, body, error } = this.state;

    return (
      <div id='app'>
        <CreateEditCategoryModal
          categoryTitle={ categoryTitle }
          isOpenCategoryModal={ globalState.isOpenCategoryModal || globalState.isOpenEditCategoryModal }
          toggleCategoryModal={ category ? this.toggleEditCategoryModal : this.toggleAddCategoryModal }
          onCategoryTitleChange={ this.onCategoryTitleChange }
          onClick={ category ? this.onCategoryEdit : this.onCategoryCreate }
          buttonTitle={ category ? 'edit' : 'create' }
          modalTitle={ category ? 'Edit Category' : 'Add Category' }
          error={ error }
        />
        <DeleteCategoryConfirmationModal
          isOpenDeleteCategoryModal={ globalState.isOpenDeleteCategoryModal }
          toggleDeleteCategoryModal={ this.toggleDeleteCategoryModal }
          onCategoryDelete={ this.onCategoryDelete }
        />
        <ReportModal
          isOpen={ globalState.isOpenReportModal }
          onClose={ this.toggleReportModal }
          report={ report }
          onReportChange={ this.onReportChange }
          title={ globalState.type === 'comment' ? 'Comment Report' : 'Reply Report' }
          buttonTitle={ reportedSuccess ? 'ok' : 'Send'}
          type={ globalState.type }
          isSent={ !!reportedSuccess }
          onClick={ reportedSuccess ? this.toggleReportModal : this.sendReport }
          reportedSuccess={ reportedSuccess }
          error={ error }
        />
        <EditCommentReplyModal
          type={ globalState.type }
          isOpen={ globalState.isOpenEditCommentReplyModal }
          body={ body }
          onChangeBody={ this.onChangeBody }
          onClose={ this.onCloseEditCommentReplyModal }
          title={ globalState.type === 'comment' ? 'Edit comment' : 'Edit reply' }
          buttonTitle='edit'
          onClick={ this.onSaveEdditedCommentReply }
          error={ error }
        />
        <DeleteCommentReplyModal
          isOpenDeleteCommentReplyModal={ globalState.isOpenDeleteCommentReplyModal }
          toggleDeleteCommentReplyModal={ this.toggleDeleteCommentReplyModal }
          onDelete={ globalState.type === 'comment' ? this.onDeleteComment : this.onDeleteReply }
          type={ globalState.type }
        />
        <DeleteConsultationConfirmationModal
          isOpenDeleteConsultationModal={ globalState.isOpenDeleteConsultationModal }
          toggleDeleteConsultationModal={ this.toggleDeleteConsultationModal }
          onConsultationDelete={ this.onConsultationDelete }
        />
        { children }
      </div>
    );
  }
}

export default App;