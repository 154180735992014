import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import ReactQuill from 'react-quill';
import { titleModules, titleFormats } from '../../../constants';

class TitleInputWithEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false
    };
  }

  onTextChange = (content, delta, source, editor) => {
    this.props.onChange(editor);
  }

  onFocus = () => {
    this.props.onFocus(this.props.name);
    this.setState(({ focused: true }), () => {
      this.quillEditor.focus();
    });
  }

  onBlur = () => {
    this.props.onBlur();
    this.setState({ focused: false });
  }

  render() {
    const { focused } = this.state;
    const { value, placeholder, isAnotherInputFocused, name, sponsor_title, sponsor_logo_url, title } = this.props;

    return (
      <div className="editor-title">
        <div className='title-text'>
          { focused && isAnotherInputFocused === name ?
            <ReactQuill
              name={ name }
              ref={ ref => this.quillEditor = ref }
              placeholder={ placeholder }
              value={ value || '' }
              onChange={ this.onTextChange }
              modules={ titleModules }
              formats={ titleFormats }
              className={ `hide-border quill-toolbar-position` }
            /> :
            <div tabIndex="0" onFocus={ this.onFocus }>
              <span className={ `${ name === 'title' ? 'editor-subcategory-text' : 'editor-title-text' }` }>{ value ? ReactHtmlParser(value) : placeholder }</span>
            </div>
          }
          { sponsor_title &&
            <a href="/">
              <div className="navigation-sponsor">
                <div className='support-logo'>
                  <img src={ sponsor_logo_url } alt="sponsor logo" />
                </div>
                <div className='sponsor-title'>
                  <p>{ title }</p>
                  <p>{ sponsor_title }</p>
                </div>
              </div>
            </a>
          }
        </div>
      </div>
    )
  }
}

export default TitleInputWithEditor;