import React from 'react';
import Modal from 'react-modal';
import { ModalInput, MenuCross, AuthenticationButton } from '../../';

const CreateEditCategoryModal = ({
  modalTitle,
  isOpenCategoryModal,
  toggleCategoryModal,
  onCategoryTitleChange,
  categoryTitle,
  onClick,
  buttonTitle,
  error
}) => (
  <Modal
    isOpen={ isOpenCategoryModal }
    onRequestClose={ toggleCategoryModal }
    contentLabel="AddCategoryModal"
    className='modal'
    appElement={ document.getElementById('app') }
  >
    <div className='modal-container'>
      { error &&
        <span className='main-auth-error' style={{ marginLeft: '0', textAlign: 'center' }}>{ error }</span>
      }
      <span className='title'>{ modalTitle }</span>
      <button
        className="modal-close-btn"
        tabIndex="1"
        onClick={ toggleCategoryModal }
      >
        { MenuCross }
      </button>
      <ModalInput
        onInputChange={ onCategoryTitleChange }
        value={ categoryTitle }
        placeholder={ 'Enter Category Title' }
        maxLength={ 120 }
      />
      <AuthenticationButton
        title={ buttonTitle }
        onClick={ onClick }
      />
    </div>
  </Modal>
);

export default CreateEditCategoryModal;