import React from 'react';

const Select = ({ options, name, error, handleSelect, styleClass, className = "form-row", label = null, value }) => (
  <div className={className}>
    {label && <label htmlFor={name}>{label}</label>}
    <select
      name={ name }
      id={ name }
      onChange={ handleSelect }
      className={ styleClass }
    >
      { options.map(option => (
          <option
            key={ option.value }
            value={ option.value }
            selected={option.value === value}
          >
            { option.label }
          </option>
        ))
      }
    </select>
    <span className='error-text'>{
      error ? error : 'Error'
    }</span>
  </div>
);

export default Select;
