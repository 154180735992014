import { INITIAL_STATE, CURRENT_USER_ACTIONS } from '../constants';

export default function currentUserReducer(state = INITIAL_STATE.CURRENT_USER, action) {
  const { value, error, errors, message } = action.payload || {};

  switch(action.type) {
    case CURRENT_USER_ACTIONS.GET_CURRENT_USER_SUCCESS:
      return { ...state, value, updateErrors: null, error: false }
    case CURRENT_USER_ACTIONS.GET_CURRENT_USER_ERROR:
      return { ...state, error }
    case CURRENT_USER_ACTIONS.UPDATE_CURRENT_USER_SUCCESS:
      return { ...state, message, updateErrors: null, error: false };
    case CURRENT_USER_ACTIONS.UPDATE_CURRENT_USER_ERROR:
      return { ...state, updateErrors: errors }
    default:
      return state;
  }
};
