import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reportsActions, globalActions } from '../../actions';
import { Reports } from '../../components';

const mapStateToProps = state => {
  return {
    reports: state.reportsState.reports
  };
}

const mapDispatchToProps = dispatch => {
  return {
    reportsActions: bindActionCreators(reportsActions, dispatch),
    globalActions: bindActionCreators(globalActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports);