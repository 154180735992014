import React from 'react';
import Linkify from 'react-linkify';
import HeroSmall from '../../../imgs/u-hero-small.png';
import Hero from '../../../imgs/u-hero.png';

const AuthHeader = ({ title, description }) => (
  <section className='sec-dashboard-search authorization-hero'>
    <div className="search-img limit-width">
      <picture>
        <source media="(max-width: 575px)" srcSet={ HeroSmall } />
        <source media="(min-width: 576px)" srcSet={ Hero } />
        <img src={Hero} alt="doc"/>
      </picture>
    </div>
    <div className="search-controls">
      <div className="container">
        <p>{ title }</p>
        <p>
          <Linkify
            componentDecorator={ text =>
              <a href={ text } target='_blank' rel='noopener noreferrer'>{ text }</a>
            }
          >
            { description }
          </Linkify>
        </p>
      </div>
    </div>
  </section>
);

export default AuthHeader;