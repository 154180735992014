import React from 'react';
import { Input, AuthenticationButton } from '../../';

const ForgotPasswordForm = ({ onSubmit, errors, handleEmail, email }) => (
  <form onSubmit={ onSubmit }>
    <Input
      type='email'
      placeholder='Email Address'
      styleClass={ errors && errors.email ? 'error' : '' }
      error={ errors && errors.email && errors.email }
      onInputChange={ handleEmail }
      value={ email }
    />
    <AuthenticationButton title='SEND LINK' />
  </form>
);

export default ForgotPasswordForm;