import React from 'react';
import Modal from 'react-modal';
import { MenuCross, AuthenticationButton } from '../../';

const DeleteConsultationConfirmationModal = ({ isOpenDeleteConsultationModal, toggleDeleteConsultationModal, onConsultationDelete }) => (
  <Modal
    isOpen={ isOpenDeleteConsultationModal }
    onRequestClose={ toggleDeleteConsultationModal }
    contentLabel="DeleteConsultationModal"
    className='modal'
    appElement={ document.getElementById('app') }
  >
    <div className='modal-container'>
      <span className='title'>Are you sure you want to delete this consultation</span>
      <button
        className="modal-close-btn"
        tabIndex="1"
        onClick={ toggleDeleteConsultationModal }
      >
        { MenuCross }
      </button>
      <AuthenticationButton
        title='Delete'
        onClick={ onConsultationDelete }
      />
    </div>
  </Modal>
);

export default DeleteConsultationConfirmationModal;