import React, { Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect} from 'react-redux';

import { currentUserActions } from '../../actions';

class MainWrapper extends Component {
  componentDidMount() {
    this.props.currentUserActions.getCurrentUser();
  }

  render() {
    return (
      <>
        {this.props.children}
      </>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return { currentUserActions: bindActionCreators(currentUserActions, dispatch) }
}


export default connect(
  () => ({}),
  mapDispatchToProps
)(MainWrapper);
