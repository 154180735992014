import { connect } from 'react-redux';
import { Updates } from '../../components';
import { bindActionCreators } from 'redux';
import { consultationsActions } from '../../actions';

function mapStateToProps({
  consultationsState: {meta, consultations},
  authState: {user},
}) {
  return {
    values: consultations,
    isAllLoaded: (meta.page * 10 > consultations.length),
    meta,
    user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getUpdatedConsultations: bindActionCreators(consultationsActions.getUpdatedConsultations, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Updates);
