import React, { Component } from 'react';
import _ from 'lodash';
import { AuthHeader, FormContainer, ForgotPasswordForm } from '../../';
import { EMAIL } from '../../../constants';

class ForgotPassword extends Component {
  constructor() {
    super();

    this.state = {
      email: ''
    };
  }

  handleEmail = event => {
    const email = event.target.value;

    this.setState({ email });
  }

  onSubmit = event => {
    event.preventDefault();

    const { email } = this.state;
    const { authActions: { forgotPassword } } = this.props;
    let errors = {};

    if(!email || email === '') {
      errors.email = 'Please enter your email.';
    } else if(!EMAIL.test(email)) {
      errors.email = 'Invalid email address.';
    }
    if(_.isEmpty(errors)) {
      forgotPassword(email);
    } else {
      this.setState({ errors });
    }
  }

  render() {
    const { authState: { errorMessage } } = this.props;

    return (
      <main>
        <AuthHeader
          title='Password Recovery'
          description="We’ll send you an email with a link to create a new password."
        />
        <FormContainer classStyle='recovery'>
          { errorMessage &&
            <span className='main-auth-error'>{ errorMessage }</span>
          }
          <ForgotPasswordForm
            handleEmail={ this.handleEmail }
            onSubmit={ this.onSubmit }
            { ...this.state }
          />
        </FormContainer>
      </main>
    )
  }
}

export default ForgotPassword;