import {PAYMENT_ACTIONS} from '../constants';

export const startFreeTrial = () => ({
  type: PAYMENT_ACTIONS.START_FREE_TRIAL,
});

export const startFreeTrialSuccess = () => ({
  type: PAYMENT_ACTIONS.START_FREE_TRIAL_SUCCESS,
});

export const startFreeTrialError = error => ({
  type: PAYMENT_ACTIONS.START_FREE_TRIAL_ERROR,
  payload: {error},
});

export const createPayment = token => ({
  type: PAYMENT_ACTIONS.CREATE_PAYMENT,
  payload: {token}
});

export const createPaymentSuccess = () => ({
  type: PAYMENT_ACTIONS.CREATE_PAYMENT_SUCCESS,
});

export const createPaymentError = error => ({
  type: PAYMENT_ACTIONS.CREATE_PAYMENT_ERROR,
  payload: {error}
});

export const getSubscriptions = () => ({
  type: PAYMENT_ACTIONS.GET_SUBSCRIPTIONS,
});

export const getSubscriptionsSuccess = subscriptions => ({
  type: PAYMENT_ACTIONS.GET_SUBSCRIPTIONS_SUCCESS,
  payload: {subscriptions}
});

export const getSubscriptionsError = error => ({
  type: PAYMENT_ACTIONS.GET_SUBSCRIPTIONS_ERROR,
  payload: {error}
});