import keymirror from 'keymirror';

export default keymirror({
  GET_CURRENT_USER: null,
  GET_CURRENT_USER_SUCCESS: null,
  GET_CURRENT_USER_ERROR: null,
  UPDATE_CURRENT_USER: null,
  UPDATE_CURRENT_USER_SUCCESS: null,
  UPDATE_CURRENT_USER_ERROR: null,
});
