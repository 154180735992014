import React, { Component } from 'react';
import { Nugget } from '../../';
import { debounce } from 'lodash';

class Nuggets extends Component {
  debouncedLoadMore = debounce(this.onLoadMore, 300);

  componentDidMount() {
    const {getNuggets, meta} = this.props;
    const newMeta = {
      ...meta,
      page: 1,
    };
    getNuggets(newMeta);
  }

  onLoadMore() {
    const {getNuggets, meta} = this.props;
    const newMeta = {
      ...meta,
      page: meta.page + 1,
    };
    getNuggets(newMeta);
  }

  renderUpdatesList = () => {
    const { values, user } = this.props;
    const keys = Object.keys(values);
    return keys.map(key => (
      <div className="list-container list-letter-container" key={key}>
        <span className="list-letter">{key}</span>
        {values[key].map(value => (
          <Nugget key={value.id} value={value} user={user} />
        ))}
      </div>
    ));
  }

  render() {
    return (
      <section className="sec-activity">
        <div className="container">
          <div className="activity-title">
            <p>Nuggets</p>
          </div>
          <div className="activity-list">
            <div className="list-container">
              {this.renderUpdatesList()}
            </div>

            {!this.props.isAllLoaded && <div className="list-footer">
              <button onClick={this.debouncedLoadMore.bind(this)} className="btn large filled">SHOW MORE</button>
            </div>}
          </div>
        </div>
      </section>
    );
  }
}

export default Nuggets;
